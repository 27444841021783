<template>
  <div
    class="z-1 flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto min-h-screen"
  >
    <div class="mt-5 flex-grow flex flex-col">
      <nav class="flex-1 px-2 space-y-8 bg-white" aria-label="Sidebar">
        <div
          class="space-y-1"
          v-for="parent in elements"
          :key="parent.slug"
        >
          <h3
            class="px-3 text-sm font-medium text-gray-500"
            id="projects-headline"
          >
            <span v-if="parent.elements && parent.elements.length > 0">{{ parent.title }}</span>
            <span v-else>
              <a
                @click="$emit('changePage', {contentPath: null, parentPath: parent.slug})"
                :class="[
                  currentParent === parent.slug
                    ? 'text-gray-800 bg-gray-100 hover:text-gray-900 hover:bg-gray-200'
                    : 'bg-white text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                  'group flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer',
                ]"
              >
                {{ parent.title }}
              </a>
            </span>
          </h3>
          <div
            class="space-y-1"
            role="group"
            aria-labelledby="projects-headline"
            v-if="parent.elements && parent.elements.length > 0"
          >
            <a
              v-for="item in parent.elements"
              :key="item.slug"
              @click="$emit('changePage', {contentPath: item.slug, parentPath: parent.slug})"
              :class="[
                currentParent === parent.slug && contentPath === item.slug
                  ? 'text-gray-800 bg-gray-100 hover:text-gray-900 hover:bg-gray-200'
                  : 'bg-white text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                'group flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer',
              ]"
            >
              <span class="">
                {{ item.title }}
              </span>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["elements", "currentParent", "contentPath"],
  data() {
    return {};
  }
};
</script>

<style></style>
