import { createStore } from "vuex";

export default createStore({
  state: {
    showBanner: false,
    search: false,
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
    workspaces: localStorage.getItem("workspaces")
      ? JSON.parse(localStorage.getItem("workspaces"))
      : [],
    avatar: localStorage.getItem("avatar") || "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    apiToken: localStorage.getItem("apiToken")
      ? localStorage.getItem("apiToken")
      : null,
    adminToken: localStorage.getItem("adminToken") || null,
    domainName: localStorage.getItem("domainName") || null,
    domainUrl: localStorage.getItem("domainUrl") || null,
    domainId: localStorage.getItem("domainId") || null,
    sidebar: false,
    account: localStorage.getItem("account") || null,
    language: localStorage.getItem("language") || "fr",
    logo: localStorage.getItem("logo") || null,
    background: localStorage.getItem("background") || null,
    isGuest: false,
  },
  mutations: {
    isGuest(state, isGuest) {
      state.isGuest = isGuest;
    },
    showBanner(state, showBanner) {
      state.showBanner = showBanner;
    },
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    apiToken(state, apiToken) {
      state.apiToken = apiToken;
    },
    adminToken(state, adminToken) {
      state.adminToken = adminToken;
    },
    domainName(state, domainName) {
      state.domainName = domainName;
    },
    domainUrl(state, domainUrl) {
      state.domainUrl = domainUrl;
    },
    domainId(state, domainId) {
      state.domainId = domainId;
    },
    avatar(state, avatar) {
      state.avatar = avatar;
    },
    account(state, account) {
      state.account = account;
    },
    language(state, language) {
      state.language = language;
    },
    search(state, search) {
      state.search = search;
    },
    logo(state, logo) {
      state.logo = logo;
    },
    background(state, background) {
      state.background = background;
    },
    workspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
  },
  actions: {
    isGuest(context, isGuest) {
      context.commit("isGuest", isGuest);
    },
    showBanner(context, showBanner) {
      context.commit("showBanner", showBanner);
    },
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
    apiToken(context, apiToken) {
      context.commit("apiToken", apiToken);
    },
    adminToken(context, adminToken) {
      context.commit("adminToken", adminToken);
    },
    domainName(context, domainName) {
      context.commit("domainName", domainName);
    },
    domainUrl(context, domainUrl) {
      context.commit("domainUrl", domainUrl);
    },
    domainId(context, domainId) {
      context.commit("domainId", domainId);
    },
    userAvatar(context, avatar) {
      context.commit("avatar", avatar);
    },
    account(context, account) {
      context.commit("account", account);
    },
    language(context, language) {
      context.commit("language", language);
    },
    search(context, search) {
      context.commit("search", search);
    },
    logo(context, logo) {
      context.commit("logo", logo);
    },
    background(context, background) {
      context.commit("background", background);
    },
    workspaces(context, workspaces) {
      context.commit("workspaces", workspaces);
    },
  },
  getters: {
    isGuest: (state) => {
      return state.isGuest;
    },
    showBanner: (state) => {
      return state.showBanner;
    },
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    apiToken: (state) => {
      return state.apiToken;
    },
    adminToken: (state) => {
      return state.adminToken;
    },
    domainName: (state) => {
      return state.domainName;
    },
    domainUrl: (state) => {
      return state.domainUrl;
    },
    domainId: (state) => {
      return state.domainId;
    },
    avatar: (state) => {
      return state.user;
    },
    language: (state) => {
      return state.language;
    },
    search: (state) => {
      return state.search;
    },
    logo: (state) => {
      return state.logo;
    },
    background: (state) => {
      return state.background;
    },
    workspaces: (state) => {
      return state.workspaces;
    },
  },
});
