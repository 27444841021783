<template>
 <div class="bg-white">
  <main class="overflow-hidden">
   <!-- Header -->
   <div>
    <div class="pt-24 lg:pt-32 pb-20 lg:pb-24">
     <div class="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
      <h1 class="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight">Get in touch</h1>
      <p class="mt-6 text-xl text-warm-gray-500 max-w-3xl">Send us your information and our team will be in contact with you.</p>
     </div>
    </div>
   </div>

   <!-- Contact section -->
   <section class="relative bg-white" aria-labelledby="contact-heading">
    <div class="absolute w-full h-1/2" aria-hidden="true" />
    <!-- Decorative dot pattern -->
    <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
     <svg class="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
      <defs>
       <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
        <rect x="0" y="0" width="4" height="4" class="text-warm-gray-200" fill="currentColor" />
       </pattern>
      </defs>
      <rect width="404" height="384" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
     </svg>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:py-2 sm:px-6 lg:px-8">
     <div class="relative bg-white shadow-xl rounded-md">
      <h2 class="sr-only">Contact us</h2>

      <div class="grid grid-cols-1 lg:grid-cols-3">
       <!-- Contact information -->
       <div class="rounded-l-md relative overflow-hidden py-10 px-6 bg-indigo-700 sm:px-10 xl:p-12">
        <div class="absolute inset-0 pointer-events-none sm:hidden" aria-hidden="true">
         <svg class="absolute inset-0 w-full h-full" width="343" height="388" viewBox="0 0 343 388" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
          <path d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z" fill="url(#linear1)" fill-opacity=".1" />
          <defs>
           <linearGradient id="linear1" x1="254.553" y1="107.554" x2="961.66" y2="814.66" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#fff" stop-opacity="0" />
           </linearGradient>
          </defs>
         </svg>
        </div>
        <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden" aria-hidden="true">
         <svg class="absolute inset-0 w-full h-full" width="359" height="339" viewBox="0 0 359 339" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
          <path d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z" fill="url(#linear2)" fill-opacity=".1" />
          <defs>
           <linearGradient id="linear2" x1="192.553" y1="28.553" x2="899.66" y2="735.66" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#fff" stop-opacity="0" />
           </linearGradient>
          </defs>
         </svg>
        </div>
        <div class="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block" aria-hidden="true">
         <svg class="absolute inset-0 w-full h-full" width="160" height="678" viewBox="0 0 160 678" fill="none" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
          <path d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z" fill="url(#linear3)" fill-opacity=".1" />
          <defs>
           <linearGradient id="linear3" x1="192.553" y1="325.553" x2="899.66" y2="1032.66" gradientUnits="userSpaceOnUse">
            <stop stop-color="#fff" />
            <stop offset="1" stop-color="#fff" stop-opacity="0" />
           </linearGradient>
          </defs>
         </svg>
        </div>
        <h3 class="text-lg font-medium text-white">Contact information</h3>
        <p class="mt-6 text-base text-indigo-50 max-w-3xl">Our team of experts combines statistical expertise with a deep understanding of 3CX to create general or specific solutions for your needs.</p>
        <dl class="mt-8 space-y-6">
         <dt>
          <span class="sr-only">Phone number</span>
         </dt>
         <dd class="flex text-base text-indigo-50">
          <PhoneIcon class="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
          <span class="ml-3">+1 (555) 123-4567</span>
         </dd>
         <dt>
          <span class="sr-only">Email</span>
         </dt>
         <dd class="flex text-base text-indigo-50">
          <EnvelopeIcon class="flex-shrink-0 w-6 h-6 text-indigo-200" aria-hidden="true" />
          <span class="ml-3">developers@cx-engine.com</span>
         </dd>
        </dl>
        <ul role="list" class="mt-8 flex space-x-12">
         <li>
          <a class="text-indigo-200 hover:text-indigo-100" href="https://www.linkedin.com/company/cx-engine" target="_blank">
           <span class="sr-only">LinkedIn</span>

           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" fill="none" class="w-6 h-6">
            <path
             d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
             fill="currentColor"
            />
           </svg>
          </a>
         </li>
        </ul>
       </div>

       <!-- Contact form -->
       <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <h3 class="text-lg font-medium text-gray-900">Send us a message</h3>
        <form @submit.prevent="sendMail" method="POST" class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
         <div>
          <label for="first-name" class="block text-sm font-medium text-gray-900">First name</label>
          <div class="mt-1">
           <input v-model="form.firstname" type="text" name="first-name" id="first-name" autocomplete="given-name" required class="py-2 px-4 block w-full shadow-sm border border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
         </div>
         <div>
          <label for="last-name" class="block text-sm font-medium text-gray-900">Last name</label>
          <div class="mt-1">
           <input v-model="form.lastname" type="text" name="last-name" id="last-name" autocomplete="family-name" required class="py-2 px-4 block w-full shadow-sm border border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
         </div>
         <div>
          <label for="company" class="block text-sm font-medium text-gray-900 capitalize">company</label>
          <div class="mt-1">
           <input v-model="form.company" id="company" name="company" type="text" required class="py-2 px-4 block w-full shadow-sm border border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
         </div>
         <div>
          <div class="flex justify-between">
           <label for="phone" class="block text-sm font-medium text-gray-900">Phone</label>
           <span id="phone-optional" class="text-sm text-gray-500">Optional</span>
          </div>
          <div class="mt-1">
           <input v-model="form.phone" type="text" name="phone" id="phone" autocomplete="tel" class="py-2 px-4 block w-full shadow-sm border border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" aria-describedby="phone-optional" />
          </div>
         </div>
         <div class="sm:col-span-2">
          <label for="email" class="block text-sm font-medium text-gray-900 capitalize">email</label>
          <div class="mt-1">
           <input type="email" v-model="form.email" name="email" id="email" required autocomplete="email" class="py-3 px-4 block w-full shadow-sm border border-gray-300 text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
          </div>
         </div>
         <div class="sm:col-span-2">
          <fieldset>
           <legend class="block text-sm font-medium text-gray-900 pb-1">Select features</legend>
           <div v-for="feature in features" :key="feature.name" class="space-y-5">
            <div class="relative flex items-start">
             <div class="flex h-6 items-center">
              <input v-model="form[feature.value]" :id="feature.value" :aria-describedby="feature.value" :name="feature.value" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
             </div>
             <div class="ml-3 text-sm leading-6">
              <label :for="feature.value" class="font-medium text-gray-900">{{ feature.name }}</label>
              <p :id="feature.value" class="text-gray-500">
               {{ feature.description }}
              </p>
             </div>
            </div>
           </div>
          </fieldset>
         </div>
         <div class="sm:col-span-2 sm:flex sm:justify-end">
          <button type="submit" class="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto">
           Submit
          </button>
         </div>
        </form>
       </div>
      </div>
     </div>
    </div>
   </section>

   <!-- Contact grid -->
   <section aria-labelledby="offices-heading">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
     <h2 id="offices-heading" class="text-3xl tracking-tight font-bold text-warm-gray-900">Our offices</h2>
     <!-- <p
      class="mt-6 text-lg text-warm-gray-500 max-w-3xl"
     >
      Varius facilisi mauris sed sit.
      Non sed et duis dui leo, vulputate
      id malesuada non. Cras aliquet
      purus dui laoreet diam sed lacus,
      fames.
     </p> -->
     <div class="mt-10 grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-4">
      <div v-for="office in offices" :key="office.id">
       <h3 class="text-lg font-medium text-warm-gray-900">
        {{ office.city }}
       </h3>
       <p class="mt-2 text-base text-warm-gray-500">
        <span v-for="line in office.address" :key="line" class="block">
         {{ line }}
        </span>
       </p>
      </div>
     </div>
    </div>
   </section>
  </main>

  <footer>
   <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
    <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
     <img class="h-8 w-auto" src="../assets/cx-engine-logo-inline.svg" alt="cx-engine" />
    </nav>
    <p class="mt-5 text-center text-base text-gray-400">&copy; 2020 CX-engine Ltd. All rights reserved.</p>
   </div>
  </footer>
 </div>
</template>

<script>
import axios from "axios";
import Sidebar from "../components/Sidebar.vue";
import Markdown from "vue3-markdown-it";
import { EnvelopeIcon, Bars3Icon, PhoneIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const offices = [
 {
  id: 1,
  city: "Dublin",
  address: ["13 Adelaide Rd, Saint Kevin's", "Dublin, D02 P950"],
 },
];

export default {
 props: ["features"],
 components: {
  Sidebar,
  Markdown,
  EnvelopeIcon,
  Bars3Icon,
  PhoneIcon,
  XMarkIcon,
 },
 setup() {
  return {
   offices,
  };
 },
 data() {
  return {
   summary: [],
   title: "",
   markdownData: "",
   form: {
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    phone: "",
    stats: false,
    smart: false,
    crm: false,
   },
  };
 },
 methods: {
  sendMail() {
   const url = "https://xp-stats-101.bluerock.tel/api/email/cx-engine";
   axios
    .post(url, this.form)
    .then((res) => {
     console.log(".then ~ res:", res);
     this.$router.push("/confirmation");
    })
    .catch((err) => {
     console.log(err);
    });
  },
 },
 mounted() {
  window.scrollTo(0, 0);
 },
 computed: {},
};
</script>
