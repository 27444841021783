import { createApp } from "vue";
import axios from "axios";
import Markdown from "vue3-markdown-it";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import "./assets/css/main.css";

import ErrorHandling from "./mixins/ErrorHandling";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

axios.defaults.headers.common["Authorization"] = "Bearer " + store.state.token;

createApp(App)
  .use(Markdown)
  .use(store)
  .use(router)
  .use(LoadingPlugin)
  .mixin(ErrorHandling)
  .mount("#app");
