<template>
  <div>
    <HeroSection />
    <FeaturesSection />
    <ImageCtaSection />
    <StatisticsSection />
    <PartnersSection />
  </div>
</template>

<script>
import HeroSection from "../components/HeroSection.vue";
import FeaturesSection from "../components/FeaturesSection.vue";
import ImageCtaSection from "../components/ImageCtaSection.vue";
import StatisticsSection from "../components/StatisticsSection.vue";
import PartnersSection from "../components/PartnersSection.vue";

export default {
  components: {
    HeroSection,
    FeaturesSection,
    ImageCtaSection,
    StatisticsSection,
    PartnersSection,
  },
};
</script>

<style></style>
