<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <section id="features" class="bg-white dark:bg-gray-900 p-10">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Streamlines your customer experience like never before.
          </h2>
          <p class="text-gray-500 sm:text-xl dark:text-gray-400">
            CX-Engine is a powerful toolbox that connect your CRM with 3CX and provide you with deep customer insights.
            Discover what your customers want before they do, and exceed their expectations.
          </p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <PresentationChartLineIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">Real-time call analysis</h3>
          <p class="text-gray-500 dark:text-gray-400">The most advanced 3CX call analytics platform on the market. Follow a call throughout its lifetime so you can adjust your workflows and boost your team's productivity.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <IdentificationIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">Caller recognition</h3>
          <p class="text-gray-500 dark:text-gray-400">Recognize customers from various phone numbers and make smart decisions based on your own data. Route them to a VIP queue or a specific agent effortlessly.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <DocumentMagnifyingGlassIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">CRM Connector</h3>
          <p class="text-gray-500 dark:text-gray-400">Connect 3CX with your CRM systems to retrieve and store contacts and phone calls. Customize your integrations to fit your needs.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <GlobeEuropeAfricaIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">Geographic routings</h3>
          <p class="text-gray-500 dark:text-gray-400">Drive calls to the expected destination based on the caller's location to never miss a sales opportunity again.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <ClockIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">Time-based routing</h3>
          <p class="text-gray-500 dark:text-gray-400">Decide where to route calls based on the time of the day so that an available agent can answer your customer's inquiry promptly.</p>
        </div>
        <div>
          <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
            <SquaresPlusIcon class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
          </div>
          <h3 class="mb-2 text-xl font-bold dark:text-white">Multi-account management</h3>
          <p class="text-gray-500 dark:text-gray-400">Manage multiple entities from a single dashboard. Create and manage your customers' accounts and give them access to their own dashboard.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  SquaresPlusIcon,
  FingerPrintIcon,
  IdentificationIcon,
  GlobeEuropeAfricaIcon,
  ClockIcon,
  DocumentMagnifyingGlassIcon,
  PresentationChartLineIcon,
} from "@heroicons/vue/24/outline";

export default {
 components: {
  SquaresPlusIcon,
  FingerPrintIcon,
  IdentificationIcon,
  GlobeEuropeAfricaIcon,
  ClockIcon,
  DocumentMagnifyingGlassIcon,
  PresentationChartLineIcon,
 },
 data() {
  return {};
 },
};
</script>


<style></style>
