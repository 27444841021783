<template>
 <section
  class="bg-white dark:bg-gray-900 p-10"
 >
  <div
   class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6"
  >
   <Analyze class="w-full" />

   <div class="mt-4 md:mt-0">
    <h2
     class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white"
    >
     Let's bring value to your business.
    </h2>

    <p
     class="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400"
    >
     Connecting with your customers is
     one of the most important part of
     your business. CX-Engine helps you
     understand and fine-tune your 3CX
     phone system to satisfy your
     clients and let you focus on your
     job.
    </p>

    <router-link
     to="/contact-us"
     class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
    >
     Get in touch now
     <svg
      class="ml-2 -mr-1 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
       fill-rule="evenodd"
       d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
       clip-rule="evenodd"
      ></path>
     </svg>
    </router-link>
   </div>
  </div>
 </section>
</template>

<script>
import Analyze from "@/components/illustrations/Analyze";

export default {
 components: {
  Analyze,
 },
 data() {
  return {
   appUrl:
    process.env.VUE_APP_APPLICATION_URL,
  };
 },
};
</script>

<style></style>
