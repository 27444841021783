<template>
 <div class="mt-0 height-full mb-4">
  <div>
   <div class="pt-24 lg:pt-32 pb-4 lg:pb-4">
    <div class="relative z-10 max-w-3xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
     <h1
      class="text-4xl font-bold tracking-tight text-warm-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl lg:tracking-tight"
     >
      New account
     </h1>
     <p class="mt-6 text-xl text-warm-gray-500 max-w-3xl">
      First, let's create your account. Once your account has been created you can login and add a
      new workspace.
     </p>
    </div>
   </div>
  </div>
  <main class="max-w-2xl mx-auto pb-0 lg:py-0 lg:px-8 shadow-md">
   <div class="px-6 sm:px-4 lg:col-span-2 xl:p-8">
    <!-- <h3 class="text-lg font-medium text-warm-gray-900">
          Create your account
        </h3> -->
    <form @submit.prevent="onSubmit" method="POST" class="">
     <div class="">
      <div class="">
       <label for="first-name" class="block text-sm font-medium text-warm-gray-900"
        >First name</label
       >
       <div class="mt-1">
        <input
         v-model="firstName"
         type="text"
         name="first-name"
         id="first-name"
         autocomplete="given-name"
         class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 border focus:border-teal-500 border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="mt-3">
       <label for="last-name" class="block text-sm font-medium text-warm-gray-900">Last name</label>
       <div class="mt-1">
        <input
         v-model="lastName"
         type="text"
         name="last-name"
         id="last-name"
         autocomplete="family-name"
         class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 border focus:border-teal-500 border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="mt-3">
       <label for="mobile" class="block text-sm font-medium text-warm-gray-900"
        >Mobile number</label
       >
       <div class="mt-1">
        <input
         v-model="form.mobile_phone"
         type="text"
         name="mobile"
         id="mobile"
         class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 border focus:border-teal-500 border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="mt-5 border-t pt-2">
       <label for="email" class="block text-sm font-medium text-warm-gray-900">Email</label>
       <div class="mt-1">
        <input
         v-model="this.form.email"
         id="email"
         name="email"
         type="email"
         required
         class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 border focus:border-teal-500 border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="mt-3">
       <div class="flex justify-between">
        <label for="new-password" class="block text-sm font-medium text-warm-gray-900"
         >Password</label
        >
       </div>
       <div class="mt-1">
        <input
         v-model="this.form.password"
         type="password"
         name="new-password"
         id="new-password"
         required
         class="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-teal-500 border focus:border-teal-500 border-gray-300 rounded-md"
        />
       </div>
      </div>
      <div class="mt-3">
       <div class="flex justify-between">
        <label
         for="repeat-password"
         :class="[
          passwordNotEqual ? 'text-warm-gray-900' : 'text-warm-gray-900',
          'block text-sm font-medium',
         ]"
         >Repeat Password</label
        >
       </div>
       <div class="mt-1">
        <input
         v-model="repeatPassword"
         type="password"
         name="repeat-password"
         id="repeat-password"
         @focus="passwordNotEqual = false"
         required
         :class="[
          passwordNotEqual
           ? 'focus:ring-red-500 border focus:border-red-500 border-red-300 text-red-500'
           : 'focus:ring-teal-500 border focus:border-teal-500 border-gray-300 text-warm-gray-900',
          'py-3 px-4 block w-full shadow-sm rounded-md',
         ]"
        />
        <span v-if="passwordNotEqual" class="text-xs ml-1 text-red-500">Password must match</span>
       </div>
      </div>
     </div>

     <div class="w-full mt-3">
      <button
       type="submit"
       class="text-sm bg-indigo-600 hover:bg-indigo-700 text-white mt-2 w-full inline-flex items-center justify-center px-6 py-3 border rounded-md shadow-sm text-base font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
       Register
      </button>
     </div>
    </form>
   </div>
  </main>
 </div>
</template>

<script>
import axios from "axios";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { EnvelopeIcon, Bars3Icon, PhoneIcon, XMarkIcon } from "@heroicons/vue/24/outline";
export default {
 components: {
  Popover,
  PopoverButton,
  PopoverPanel,
  EnvelopeIcon,
  Bars3Icon,
  PhoneIcon,
  XMarkIcon,
 },
 data() {
  return {
   form: {
    email: "",
    password: "",
    name: "",
    mobile_phone: "",
   },
   firstName: "",
   lastName: "",
   repeatPassword: "",
   passwordNotEqual: false,
  };
 },
 methods: {
  async onSubmit() {
   this.$emit("setLoading", true);
   this.form.name = this.firstName + " " + this.lastName;
   if (this.repeatPassword && this.repeatPassword === this.form.password) {
    axios
     .post(`${process.env.VUE_APP_API_URL}/api/register`, this.form)
     .then((res) => {
      // if (res && res.message === "success") {
      //   let login = {
      //     email: res.user.email,
      //     email: res.user.email,
      //   };
      //   axios
      //     .post(`${process.env.VUE_APP_API_URL}/api/login`, this.form)
      //     .then((res) => {})
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
      axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
      localStorage.setItem("token", res.data.token);
      this.$store.dispatch("token", res.data.token);
      if (res.data.workspaces && res.data.workspaces.length > 0 && !this.domainUrl) {
       localStorage.setItem("domainName", res.data.workspaces[0].name);
       this.$store.dispatch("domainName", res.data.workspaces[0].name);
       localStorage.setItem("domainUrl", res.data.workspaces[0].domains[0]);
       this.$store.dispatch("domainUrl", res.data.workspaces[0].domains[0]);
       localStorage.setItem("domainId", res.data.workspaces[0].id);
       this.$store.dispatch("domainId", res.data.workspaces[0].id);
      }
      this.$store.dispatch("user", JSON.stringify(res.data.user));
      localStorage.setItem("user", JSON.stringify(res.data.user));
      this.$store.dispatch("workspaces", JSON.stringify(res.data.workspaces));
      localStorage.setItem("workspaces", JSON.stringify(res.data.workspaces));
      this.$store.dispatch("userId", res.data.user.id);
      this.$emit("activeBanner");
      this.$emit("success", true);
      this.$emit("successMsg", "Account created successfully.");
      this.$emit("setLoading", false);
     })
     .finally((res) => {
      location.reload();
      this.$router.push("/workspace");
     })
     .catch((err) => {
      this.errorHandling(err);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
      this.$emit("setLoading", false);
     });
   } else {
    this.passwordNotEqual = true;
   }
  },
 },
};
</script>

<style>
.impersonate_z_index {
 z-index: 9999;
}
.impersonate_card_height_position {
 min-height: 100vh;
}
</style>
