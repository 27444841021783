<template>
 <div class="flex min_height_app">
  <div
   class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
  >
   <div class="mx-auto w-full max-w-sm lg:w-96">
    <div class="mx-2">
     <router-link to="/">
      <img class="max-h-16 w-auto" src="../assets/cx-engine.png" alt="Welcome" />
     </router-link>

     <h2 class="mt-6 text-2xl font-bold text-gray-900">Sign in</h2>
    </div>

    <div class="mt-8">
     <div class="">
      <div v-show="errorLogin" class="rounded-md bg-red-50 p-2 mx-2 border border-red-200">
       <div class="flex">
        <div class="flex-shrink-0">
         <ExclamationIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
         <h3 class="text-sm font-medium text-red-800"></h3>
        </div>
       </div>
      </div>
      <div class="max-w-md w-full space-y-8">
       <div class="form_card">
        <form class="mt-8 space-y-6" @submit.prevent="onSubmit" method="POST">
         <input type="hidden" name="remember" value="true" />
         <div class="shadow-sm -space-y-px">
          <div>
           <label for="email-address" class="sr-only">Email address </label>
           <input
            id="email-address"
            v-model="form.email"
            name="email"
            type="email"
            autocomplete="email"
            required=""
            :disabled="isDisabled"
            class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            placeholder="email address"
           />
          </div>
          <div>
           <label for="password" class="sr-only">Password</label>
           <input
            id="password"
            v-model="form.password"
            name="password"
            type="password"
            autocomplete="current-password"
            :disabled="isDisabled"
            required=""
            class="appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:encom_border_input focus:z-10 sm:text-sm"
            placeholder="password"
           />
          </div>
         </div>
         <!-- 
                  <div
                    class="flex items-center justify-between"
                    v-if="!isGuestUser"
                  >
                    <div class="text-sm">
                      <a
                        @click="$emit('resetPassword', form.email)"
                        href="#"
                        class="font-medium encom_ouvert_ticket_text"
                      >
                        {{ $t("login.newPassword") }}
                      </a>
                    </div>

                    <div class="text-sm forgot_margin">
                      <a
                        @click="$emit('resetPassword', form.email)"
                        href="#"
                        class="font-medium encom_ouvert_ticket_text"
                      >
                        {{ $t("login.forgotPassword") }}
                      </a>
                    </div>
                  </div> -->

         <div>
          <button
           type="submit"
           class="rounded bg-indigo-700 hover:bg-indigo-800 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white focus:outline-none encom_ring_color"
          >
           <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <LockClosedIcon class="h-5 w-5" aria-hidden="true" />
           </span>
           Sign in
          </button>
          <p class="border-gray-300 border-t mt-6 pt-3 text-sm">
           Don't have an account ?
           <a href="/register" class="text-sky-500">Sign up</a>
          </p>
         </div>
        </form>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div class="hidden lg:block relative w-0 flex-1">
   <img
    class="absolute inset-0 h-full w-full object-cover"
    src="../assets/cxengine-dublin.jpg"
    alt=""
   />
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { LockClosedIcon } from "@heroicons/vue/24/solid";
import { mapGetters } from "vuex";

export default {
 components: { LockClosedIcon },
 name: "Login",
 data() {
  return {
   form: {
    email: "",
    password: "",
   },
  };
 },
 methods: {
  async onSubmit() {
   axios
    .post(`${process.env.VUE_APP_API_URL}/api/login`, this.form)
    .then((res) => {
     console.log("file: Login.vue ~ line 160 ~ .then ~ res", res.data);
     axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
     localStorage.setItem("token", res.data.token);
     this.$store.dispatch("token", res.data.token);
     if (res.data.workspaces && res.data.workspaces.length > 0 && !this.domainUrl) {
      localStorage.setItem("domainName", res.data.workspaces[0].name);
      this.$store.dispatch("domainName", res.data.workspaces[0].name);
      localStorage.setItem("domainUrl", res.data.workspaces[0].domains[0]);
      this.$store.dispatch("domainUrl", res.data.workspaces[0].domains[0]);
      localStorage.setItem("domainId", res.data.workspaces[0].id);
      this.$store.dispatch("domainId", res.data.workspaces[0].id);
     }
     // this.$store.dispatch("admin", res.data.user.admin);
     // localStorage.setItem("admin", res.data.user.admin);
     // localStorage.setItem("avatar", res.data.user.avatar);
     // localStorage.setItem("userId", res.data.user.id);
     this.$store.dispatch("user", JSON.stringify(res.data.user));
     localStorage.setItem("user", JSON.stringify(res.data.user));
     this.$store.dispatch("workspaces", JSON.stringify(res.data.workspaces));
     localStorage.setItem("workspaces", JSON.stringify(res.data.workspaces));
     this.$store.dispatch("userId", res.data.user.id);
     this.$forceUpdate();
    })
    .then((res) => {
     if (!this.domainUrl) {
      this.$router.push(`/workspace`);
     } else {
      this.$router.push(this.$route.query.to || "/");
     }
    })
    .finally((res) => {
     location.reload();
    })
    .catch((err) => {
     console.log(err);
     window.alert("Connection refused. Please try again later.");
     localStorage.removeItem("token");
     this.$store.dispatch("token", null);
     localStorage.removeItem("user");
     this.$store.dispatch("user", {});
     localStorage.removeItem("workspaces");
     this.$store.dispatch("workspaces", []);
    });
  },
  getBackground() {
   var divId = document.getElementById("background-img");
   divId.style.background = `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${this.background}) no-repeat center center fixed`;
   divId.style.backgroundSize = `cover`;
  },
 },
 mounted() {
  /*  if (this.background) {
      this.getBackground();
    } */
 },
 computed: {
  ...mapGetters(["logo", "background", "domainUrl"]),
 },
};
</script>

<style scoped>
.login_page {
 background: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
  url("../assets/cxengine-dublin.jpg") no-repeat center center fixed;
 -webkit-background-size: cover;
 -moz-background-size: cover;
 -o-background-size: cover;
 background-size: cover;
 z-index: 0;
}
.login_card {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
}
#input-group-3__BV_label_ {
 margin-top: 0.2rem;
}
.d-block {
 font-weight: bold;
}
button {
 justify-items: auto;
 margin-inline-end: 0.5rem;
 margin-block-start: 0.5rem;
 margin-top: 0.6rem;
}

.card {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
 max-width: 50rem;
 min-width: 30rem;
 /* border-radius: 10px; */
}
.card:hover {
 box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.404);
}

.card-img-top img:hover {
 transform: scale(1.01);
}

.min-h-screen {
 min-height: 30vh;
}
.bg-gray-50 {
 background-color: rgba(255, 255, 255, 0.733);
}
.form_card {
 /* background-color: rgba(255, 255, 255, 0.733); */
 /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 3px 10px 0 rgba(0, 0, 0, 0.404); */
 transition: 0.3s;
 padding: 0.1rem 10px 10px 10px;
 /* border-radius: 10px; */
}
.form_card #email-address {
 margin-top: -10px;
 margin-bottom: 10px;
}

.forgot_margin a {
 margin-left: 50px;
}

@media only screen and (max-width: 700px) {
 .form_card {
  max-height: 100vh;
  max-width: 50vh;
 }
 .login_page {
  overflow-x: hidden;
  overflow-y: hidden;
 }
 .max-w-md {
  max-height: 100vh;
  max-width: fit-content;
 }
 .max-w-md img {
  max-height: 150px;
 }
}
</style>
