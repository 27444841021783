<template>
  <section class="bg-white dark:bg-gray-900 p-10">
    <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-xl">
      <h2 class="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 md:mb-8 lg:mb-16 dark:text-white md:text-4xl">
        Backed by Telecom experts
      </h2>

      <div class="space-y-4 sm:grid sm:grid-cols-2 sm:gap-4 xl:gap-8 sm:space-y-0 md:mb-8 md:mt-12">
        <a
          href="https://encom-conseil.fr"
          class="block py-12 px-8 text-center bg-gray-50 rounded dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
          target="_blank"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 928.000000 1120.000000"
            preserveAspectRatio="xMidYMid meet"
            class="mx-auto h-12 text-gray-600 dark:text-gray-400"
          >
          <!-- width="928.000000pt" -->
          <!-- height="1120.000000pt" -->
            <g transform="translate(0.000000,1120.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
              <path d="M4345 11194 c-22 -2 -92 -9 -155 -15 -646 -60 -1327 -281 -1890 -614
                  -1317 -779 -2160 -2127 -2281 -3647 -15 -196 -7 -699 15 -878 120 -982 499
                  -1828 1150 -2565 91 -103 3446 -3465 3458 -3465 4 0 8 432 8 960 l0 960 109 0
                  c59 0 166 5 237 10 2416 189 4284 2206 4284 4625 0 1623 -851 3128 -2244 3970
                  -573 345 -1194 557 -1886 641 -127 15 -700 28 -805 18z m510 -1364 c293 -20
                  542 -69 815 -160 493 -164 913 -423 1281 -790 544 -542 869 -1225 950 -1995
                  15 -142 15 -498 0 -640 -120 -1137 -788 -2102 -1810 -2614 -401 -202 -834
                  -312 -1318 -337 l-133 -7 0 -676 0 -676 -1209 1210 c-664 666 -1246 1256
                  -1293 1313 -452 545 -703 1164 -758 1866 -66 844 208 1687 761 2346 471 561
                  1137 957 1849 1100 311 62 565 80 865 60z"
                  fill="currentColor" />
            </g>
          </svg>

          <p class="font-bold text-xl text-gray-700 mt-3.5">
            Encom
          </p>
          <p class="font-light text-gray-500 mt-1 mb-4">
            3CX Partner since 2018, 1500+ customers
          </p>

          <span href="https://encom-conseil.fr" class="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500 cursor-pointer">
            Visit website
            <svg class="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
            </svg>
          </span>
        </a>
        
        <a
          href="https://bluerocktel.com"
          class="block py-12 px-8 text-center bg-gray-50 rounded dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
          target="_blank"
        >
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 250.000000 145.000000"
            preserveAspectRatio="xMidYMid meet"
            class="mx-auto h-12 text-gray-600 dark:text-gray-400"
          >
            <!-- width="250.000000pt" -->
            <!-- height="145.000000pt" -->
            <g transform="translate(0.000000,145.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
              <path d="M1055 1418 c-4 -23 -15 -40 -30 -48 -21 -11 -25 -22 -27 -74 -2 -42
                -7 -60 -15 -59 -7 2 -13 -1 -13 -6 0 -5 6 -12 13 -14 7 -3 5 -6 -5 -6 -22 -1
                -23 -14 -3 -31 18 -15 20 -50 3 -50 -9 0 -8 -4 2 -10 13 -8 12 -10 -2 -10 -22
                0 -23 -8 -2 -31 14 -16 15 -39 9 -189 -7 -165 -8 -170 -27 -164 -12 3 -63 0
                -114 -7 -92 -12 -95 -13 -115 -48 -12 -19 -23 -58 -26 -85 -5 -54 -16 -76 -40
                -76 -12 0 -13 -10 -8 -55 10 -75 -1 -129 -23 -120 -34 13 -37 10 -34 -32 3
                -36 1 -42 -12 -37 -12 4 -16 0 -16 -16 0 -18 -3 -20 -13 -11 -16 13 -27 5 -27
                -21 0 -10 -15 -34 -34 -54 -33 -35 -36 -36 -65 -24 -24 10 -34 10 -45 1 -11
                -10 -20 -10 -36 -1 -19 10 -26 8 -54 -19 -28 -27 -40 -31 -99 -35 -38 -3 -75
                -10 -83 -17 -8 -7 -28 -9 -50 -6 -36 6 -64 1 -64 -13 0 -12 118 -24 230 -23
                88 0 116 5 173 27 68 26 121 33 145 17 10 -6 10 -10 -3 -21 -45 -38 1 -39 177
                -4 165 33 240 31 287 -9 50 -42 151 -35 223 15 34 24 46 23 110 -7 78 -36 101
                -33 233 36 64 34 130 65 147 70 26 7 29 6 23 -9 -18 -43 -16 -53 15 -82 39
                -36 104 -58 128 -43 10 5 24 35 32 66 8 32 17 57 21 57 4 0 12 -14 20 -31 14
                -36 83 -72 127 -67 23 3 27 7 26 33 -1 17 -8 48 -17 70 -21 55 -31 115 -20
                115 5 0 19 -18 31 -39 32 -56 102 -125 153 -152 43 -22 84 -26 197 -19 32 2
                42 7 42 20 0 19 -36 30 -102 30 -49 0 -96 20 -127 53 -20 22 -23 33 -17 67 6
                38 5 42 -29 61 -19 12 -35 25 -35 30 0 5 -67 9 -148 9 l-148 0 -22 39 c-14 24
                -34 43 -52 50 -17 5 -29 15 -28 21 3 12 -13 24 -46 34 -13 5 -17 12 -12 30 5
                20 2 25 -16 28 -21 3 -23 9 -26 66 l-3 62 -198 0 -199 0 -6 38 c-4 20 -9 54
                -13 75 -3 21 -11 41 -19 44 -8 3 -14 11 -14 18 0 9 -15 15 -42 17 l-43 3 -3
                109 c-2 90 0 112 14 127 16 17 16 19 0 25 -14 5 -15 9 -4 15 10 7 10 9 1 9
                -18 0 -16 35 3 51 14 12 14 14 -2 26 -13 10 -19 31 -22 78 -3 52 -9 69 -25 80
                -11 8 -28 31 -38 52 l-17 37 -7 -36z m-29 -115 c-11 -11 -19 6 -11 24 8 17 8
                17 12 0 3 -10 2 -21 -1 -24z m99 7 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8
                11 8 17 2 6 -6 7 -16 3 -22z m-95 -35 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15
                0 8 5 15 10 15 6 0 10 -7 10 -15z m100 0 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
                15 0 8 7 15 15 15 8 0 15 -7 15 -15z"
                fill="currentColor" />
            </g>
          </svg>

          <p class="font-bold text-xl text-gray-700 mt-3.5">
            BlueRockTEL
          </p>
          <p class="font-light text-gray-500 mt-1 mb-4">
            VoIP billing, rating, and provisioning solution
          </p>

          <span href="https://bluerocktel.com" class="flex justify-center items-center text-base font-medium text-primary-600 dark:text-primary-500 cursor-pointer">
            Visit website
            <svg class="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
 components: {},
 data() {
  return {};
 },
};
</script>


<style></style>
