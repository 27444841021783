<template>
 <div class="min-h-fit flex mx-auto max-w-7xl pt-24">
  <!-- Static sidebar for desktop -->
  <div class="hidden lg:flex top-24 h-max lg:inset-y-0 overflow-y-auto">
   <!-- lg:fixed -->
  </div>
  <div class="flex flex-col w-0 flex-1 overflow-y-auto">
   <!-- lg:pl-64 -->
   <main class="flex-1">
    <div class="py-8 xl:py-10">
     <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-7xl">
      <div class="relative pt-4 pb-16 bg-white overflow-hidden">
       <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="relative overflow-hidden">
         <div>
          <div class="mx-auto max-w-5xl px-6 lg:px-8">
           <div class="mx-auto max-w-2xl lg:mx-0">
            <h2 class="text-base font-semibold leading-7 text-indigo-600">
             Enhancing Communication Efficiency: A Deep Dive into 3CX Smart
             Routings
            </h2>
            <p class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
             What is 3CX Smart Routings?
            </p>
            <p class="mt-6 text-lg leading-8 text-gray-700">
             Although it may perform other functions within your applications,
             the most common use case for smart routings is the context of an
             incoming call flow. Your goal is to connect the caller with the
             right person inside the organisation, in a quick and easy way. When
             you can not access easily to the data or if you miss the right API
             to interact with, our smart routings solution is exactly what you
             need: a turn-key API, easy and ready to use. It comes with three
             types of routings :
            </p>
           </div>
           <dl
            class="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            <div class="relative pl-9">
             <dt class="inline font-semibold">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
               <path
                d="M15.5 2A1.5 1.5 0 0014 3.5v13a1.5 1.5 0 001.5 1.5h1a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0016.5 2h-1zM9.5 6A1.5 1.5 0 008 7.5v9A1.5 1.5 0 009.5 18h1a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 6h-1zM3.5 10A1.5 1.5 0 002 11.5v5A1.5 1.5 0 003.5 18h1A1.5 1.5 0 006 16.5v-5A1.5 1.5 0 004.5 10h-1z" />
              </svg>
              Based on the caller recognition.
             </dt>
             <dd class="inline">
              This collection allows you to redirect calls based on saved
              metadata (custom fields) for a contact identified by the caller's
              number. If the caller is not initially recognized by their number,
              you can still use IVRs to retrieve the metadata based on some
              input, such as a customer account.
             </dd>
            </div>
            <div class="relative pl-9">
             <dt class="inline font-semibold">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
               <path
                fill-rule="evenodd"
                d="M12.577 4.878a.75.75 0 01.919-.53l4.78 1.281a.75.75 0 01.531.919l-1.281 4.78a.75.75 0 01-1.449-.387l.81-3.022a19.407 19.407 0 00-5.594 5.203.75.75 0 01-1.139.093L7 10.06l-4.72 4.72a.75.75 0 01-1.06-1.061l5.25-5.25a.75.75 0 011.06 0l3.074 3.073a20.923 20.923 0 015.545-4.931l-3.042-.815a.75.75 0 01-.53-.919z"
                clip-rule="evenodd" />
              </svg>
              Based on the caller geographic location.
             </dt>
             <dd class="inline">
              This feature allows you to redirect calls based on the geographic
              location of the caller. Several geographic models are available,
              including global regions, countries, or local districts.
             </dd>
            </div>
            <div class="relative pl-9">
             <dt class="inline font-semibold">
              <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 20 20"
               fill="currentColor"
               class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
               <path
                fill-rule="evenodd"
                d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z"
                clip-rule="evenodd" />
               <path
                fill-rule="evenodd"
                d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z"
                clip-rule="evenodd" />
              </svg>
              Based on the current day or time.
             </dt>
             <dd class="inline">
              This collection allows you to redirect the calls based on the
              current day or time. You could for example use this feature to
              redirect calls to a different destination during holidays, such as
              a voicemail.
             </dd>
            </div>
            <!-- <div class="relative pl-9">
              <dt class="inline font-semibold">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
                <path
                 fill-rule="evenodd"
                 d="M14.5 10a4.5 4.5 0 004.284-5.882c-.105-.324-.51-.391-.752-.15L15.34 6.66a.454.454 0 01-.493.11 3.01 3.01 0 01-1.618-1.616.455.455 0 01.11-.494l2.694-2.692c.24-.241.174-.647-.15-.752a4.5 4.5 0 00-5.873 4.575c.055.873-.128 1.808-.8 2.368l-7.23 6.024a2.724 2.724 0 103.837 3.837l6.024-7.23c.56-.672 1.495-.855 2.368-.8.096.007.193.01.291.01zM5 16a1 1 0 11-2 0 1 1 0 012 0z"
                 clip-rule="evenodd" />
                <path
                 d="M14.5 11.5c.173 0 .345-.007.514-.022l3.754 3.754a2.5 2.5 0 01-3.536 3.536l-4.41-4.41 2.172-2.607c.052-.063.147-.138.342-.196.202-.06.469-.087.777-.067.128.008.257.012.387.012zM6 4.586l2.33 2.33a.452.452 0 01-.08.09L6.8 8.214 4.586 6H3.309a.5.5 0 01-.447-.276l-1.7-3.402a.5.5 0 01.093-.577l.49-.49a.5.5 0 01.577-.094l3.402 1.7A.5.5 0 016 3.31v1.277z" />
               </svg>
               Proactive Maintenance.
              </dt>
              <dd class="inline">
               Stay ahead of system disruptions. Our statistical models can
               predict potential problems, enabling you to take proactive
               measures and prevent downtime.
              </dd>
             </div>
             <div class="relative pl-9">
              <dt class="inline font-semibold">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
                <path
                 fill-rule="evenodd"
                 d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.536-4.464a.75.75 0 10-1.061-1.061 3.5 3.5 0 01-4.95 0 .75.75 0 00-1.06 1.06 5 5 0 007.07 0zM9 8.5c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S7.448 7 8 7s1 .672 1 1.5zm3 1.5c.552 0 1-.672 1-1.5S12.552 7 12 7s-1 .672-1 1.5.448 1.5 1 1.5z"
                 clip-rule="evenodd" />
               </svg>
               Enhanced User Experience.
              </dt>
              <dd class="inline">
               Understand how your employees and customers interact with 3CX.
               Customize your system to match user preferences, boosting
               satisfaction and productivity.
              </dd>
             </div>
             <div class="relative pl-9">
              <dt class="inline font-semibold">
               <svg
                class="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true">
                <path
                 fill-rule="evenodd"
                 d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                 clip-rule="evenodd" />
               </svg>
               Security Safeguards.
              </dt>
              <dd class="inline">
               Protect your sensitive data and communications. Expert Statistics
               can identify anomalies and potential security threats within your
               system, allowing you to fortify your defenses.
              </dd>
             </div>
             <div class="relative pl-9">
              <dt class="inline font-semibold">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
                <path
                 d="M10.75 10.818v2.614A3.13 3.13 0 0011.888 13c.482-.315.612-.648.612-.875 0-.227-.13-.56-.612-.875a3.13 3.13 0 00-1.138-.432zM8.33 8.62c.053.055.115.11.184.164.208.16.46.284.736.363V6.603a2.45 2.45 0 00-.35.13c-.14.065-.27.143-.386.233-.377.292-.514.627-.514.909 0 .184.058.39.202.592.037.051.08.102.128.152z" />
                <path
                 fill-rule="evenodd"
                 d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-6a.75.75 0 01.75.75v.316a3.78 3.78 0 011.653.713c.426.33.744.74.925 1.2a.75.75 0 01-1.395.55 1.35 1.35 0 00-.447-.563 2.187 2.187 0 00-.736-.363V9.3c.698.093 1.383.32 1.959.696.787.514 1.29 1.27 1.29 2.13 0 .86-.504 1.616-1.29 2.13-.576.377-1.261.603-1.96.696v.299a.75.75 0 11-1.5 0v-.3c-.697-.092-1.382-.318-1.958-.695-.482-.315-.857-.717-1.078-1.188a.75.75 0 111.359-.636c.08.173.245.376.54.569.313.205.706.353 1.138.432v-2.748a3.782 3.782 0 01-1.653-.713C6.9 9.433 6.5 8.681 6.5 7.875c0-.805.4-1.558 1.097-2.096a3.78 3.78 0 011.653-.713V4.75A.75.75 0 0110 4z"
                 clip-rule="evenodd" />
               </svg>
               Cost Efficiency.
              </dt>
              <dd class="inline">
               Save on operational costs by analyzing call data and usage
               patterns. Optimize your 3CX investment and allocate resources
               where they matter most.
              </dd>
             </div>
             <div class="relative pl-9">
              <dt class="inline font-semibold">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="absolute left-1 top-1 h-5 w-5 text-indigo-500">
                <path
                 fill-rule="evenodd"
                 d="M.99 5.24A2.25 2.25 0 013.25 3h13.5A2.25 2.25 0 0119 5.25l.01 9.5A2.25 2.25 0 0116.76 17H3.26A2.267 2.267 0 011 14.74l-.01-9.5zm8.26 9.52v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.615c0 .414.336.75.75.75h5.373a.75.75 0 00.627-.74zm1.5 0a.75.75 0 00.627.74h5.373a.75.75 0 00.75-.75v-.615a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625zm6.75-3.63v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75v.625c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75zM17.5 7.5v-.625a.75.75 0 00-.75-.75H11.5a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75h5.25a.75.75 0 00.75-.75zm-8.25 0v-.625a.75.75 0 00-.75-.75H3.25a.75.75 0 00-.75.75V7.5c0 .414.336.75.75.75H8.5a.75.75 0 00.75-.75z"
                 clip-rule="evenodd" />
               </svg>
               Compliance Made Easy.
              </dt>
              <dd class="inline">
               Generate compliance reports effortlessly, ensuring you meet
               regulatory requirements without the hassle.
              </dd>
             </div> -->
           </dl>
           <div class="mx-auto lg:mx-0 mt-10">
            <p class="mt-6 text-lg leading-8 text-gray-700">
             Your call-flow (or any application you build) can combine those
             three types. Every one of those methods can be filled using a
             simple web form or by using our API. It is also possible to
             exchange CSV files containing your contacts for the caller
             recognition based routing. <br />
             Feel free to read our
             <router-link class="font-extrabold">case studies</router-link>
             to see how our smart routings APIs can serve your needs.
            </p>
           </div>
           <div class="mx-auto lg:mx-0 mt-10">
            <p class="mt-6 text-md leading-8 text-gray-700">
             <router-link class="font-extrabold" to="/contact-us"
              >Contact</router-link
             >
             us today for a consultation and unlock the full potential of your
             3CX system with Smart Routings.
            </p>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </main>
  </div>
 </div>
</template>

<script>
import {
 ArchiveBoxIcon,
 ClockIcon,
 HomeIcon,
 Bars3BottomLeftIcon,
 UserCircleIcon as UserCircleIconOutline,
 Bars4Icon,
 XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
 props: [""],
 components: {
  ArchiveBoxIcon,
  ClockIcon,
  HomeIcon,
  Bars3BottomLeftIcon,
  UserCircleIconOutline,
  Bars4Icon,
  XMarkIcon,
 },
 data() {
  return {};
 },

 computed: {},
};
</script>

<style>
.wrap__text {
 white-space: initial;
}
.wrap__text a {
 color: royalblue;
 text-decoration: none;
}
.wrap__text h1,
.wrap__text h2,
.wrap__text h3 {
 display: block;
 font-weight: bold;
}
.wrap__text h1,
.wrap__text h2 {
 font-size: 1.24em;
 margin: 2.2em 0 0.35em 0;
}
.wrap__text h3 {
 font-size: 1.17em;
 margin: 1em 0 0 0;
}
.wrap__text p {
 display: block;
 margin: 0.8em 0;
}
.wrap__text ul {
 display: block;
 list-style: none;
 margin: 1em 0;
 padding-left: 35px;
}
.wrap__text ul li {
 display: list-item;
}
.wrap__text ul li::before {
 content: "\2022";
 color: #565656;
 font-weight: bold;
 display: inline-block;
 width: 1em;
 margin-left: -1em;
}
</style>
