<template>
  <div class="bg-gray-50">
    <div class="relative overflow-hidden">
      <div class="relative pt-6 pb-8 sm:pb-10">
        <div class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
          <div class="text-center">
            <h1
              class="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl sm:tracking-tight md:text-6xl md:tracking-tight">
              <span class="block">Data Engineering</span>
              <span class="block text-primary-600">for a Better Customer Experience</span>
            </h1>
            <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              CX-Engine is a cloud-based platform that brings tools to boost your 3CX phone systems and your productivity.
              From complex call flows logic interacting with your CRM or ERP systems to advanced call logs and analytics, we have got you covered.
            </p>
            <div class="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div class="w-full flex sm:flex-row">
                <a
                  href="#features"
                  class="mx-1 hover:scale-102 flex rounded-md shadow items-center justify-center px-8 py-3 border border-gray-100 text-base font-medium rounded-md text-primary-600 bg-gray-100 hover:bg-gray-200 md:py-3 md:text-lg md:px-10"
                  title="View features"
                >
                  View features
                </a>
                <router-link
                  to="/contact-us"
                  class="mx-1 hover:scale-102 flex rounded-md shadow items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 md:py-3 md:text-lg md:px-10"
                  title="Get in touch"
                >
                  Get in touch now
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative">
        <DashboardAnalytics class="relative max-w-xl mx-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardAnalytics from "@/components/illustrations/DashboardAnalytics";

export default {
 components: {
  DashboardAnalytics,
 },
 data() {
  return {
   appUrl: process.env.VUE_APP_APPLICATION_URL
  };
 },
};
</script>


<style></style>
