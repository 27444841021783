<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />
 <div
  v-if="this.$route.path !== '/login'"
 >
  <div class="z-1">
   <div
    class="relative overflow-hidden"
   >
    <!-- <div class="inset-y-0 h-full w-full" aria-hidden="true" v-if="this.$route.path !== '/login'">
     <div class="relative h-full">
      <svg
       class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
       width="404"
       height="784"
       fill="none"
       viewBox="0 0 404 784"
      >
       <defs>
        <pattern
         id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
         x="0"
         y="0"
         width="20"
         height="20"
         patternUnits="userSpaceOnUse"
        >
         <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
       </defs>
       <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
      </svg>
      <svg
       class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
       width="404"
       height="784"
       fill="none"
       viewBox="0 0 404 784"
      >
       <defs>
        <pattern
         id="d2a68204-c383-44b1-b99f-42ccff4e5365"
         x="0"
         y="0"
         width="20"
         height="20"
         patternUnits="userSpaceOnUse"
        >
         <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
        </pattern>
       </defs>
       <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
      </svg>
     </div>
    </div> -->
    <Popover
     class="bg-white shadow fixed top-0 left-0 right-0 z-20"
     v-if="
      this.$route.path !== '/login'
     "
    >
     <div
      class="max-w-7xl mx-auto px-4 sm:px-6"
     >
      <div
       class="flex justify-between items-center py-6 md:justify-start md:space-x-10"
      >
       <div
        class="flex justify-start lg:w-0 lg:flex-1"
       >
        <a href="/">
         <span class="sr-only"
          >CX-Engine</span
         >
         <img
          class="h-8 w-auto sm:h-10"
          src="./assets/cx-engine-logo-inline.svg"
          alt=""
         />
        </a>
       </div>
       <div
        class="-mr-2 -my-2 md:hidden"
       >
        <PopoverButton
         class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
        >
         <span class="sr-only"
          >Open menu</span
         >
         <Bars3Icon
          class="h-6 w-6"
          aria-hidden="true"
         />
        </PopoverButton>
       </div>
       <PopoverGroup
        as="nav"
        class="hidden md:flex space-x-10 items-center"
       >
        <Popover
         class="relative"
         v-slot="{ open }"
        >
         <PopoverButton
          :class="[
           open
            ? 'text-gray-900'
            : 'text-gray-500',
           'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none',
          ]"
         >
          <span>Solutions</span>
          <ChevronDownIcon
           :class="[
            open
             ? 'text-gray-600'
             : 'text-gray-400',
            'ml-2 h-5 w-5 group-hover:text-gray-500',
           ]"
           aria-hidden="true"
          />
         </PopoverButton>

         <transition
          enter-active-class="transition ease-out duration-200"
          enter-from-class="opacity-0 translate-y-1"
          enter-to-class="opacity-100 translate-y-0"
          leave-active-class="transition ease-in duration-150"
          leave-from-class="opacity-100 translate-y-0"
          leave-to-class="opacity-0 translate-y-1"
         >
          <PopoverPanel
           class="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
          >
           <div
            class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
           >
            <div
             class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
            >
             <a
              v-for="item in features"
              :key="item.name"
              :href="item.href"
              class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
             >
              <component
               :is="item.icon"
               class="flex-shrink-0 h-6 w-6 text-primary-600"
               aria-hidden="true"
              />
              <div class="ml-4">
               <p
                class="text-base font-medium text-gray-900"
               >
                {{ item.name }}
               </p>
               <p
                class="mt-1 text-sm text-gray-500"
               >
                {{ item.description }}
               </p>
              </div>
             </a>
            </div>
            <div
             class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-8"
            >
             <div
              v-for="item in callsToAction"
              :key="item.name"
              class="flow-root"
             >
              <a
               :href="item.href"
               class="-m-3 p-3 flex items-center rounded-md text-sm font-medium text-gray-900 hover:bg-gray-100"
              >
               <component
                :is="item.icon"
                class="flex-shrink-0 h-6 w-6 text-gray-400"
                aria-hidden="true"
               />
               <span class="ml-1">{{
                item.name
               }}</span>
              </a>
             </div>
            </div>
           </div>
          </PopoverPanel>
         </transition>
        </Popover>

        <!-- <a href="#" class="text-base font-medium text-gray-500 hover:text-gray-900"> Pricing </a> -->
        <div
         class="flex items-center gap-8"
        >
         <!-- <router-link to="/login" class="text-base font-medium text-gray-500 hover:text-gray-900">
          Sign in
         </router-link> -->
         <div class="rounded-md shadow">
          <router-link
           v-if="
            $route.name !== 'ContactUs'
           "
           to="/contact-us"
           class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
          >
           Contact us
          </router-link>
          <router-link
           v-else
           to="/"
           class="inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900"
          >
           Home
          </router-link>
         </div>
        </div>
        <!-- <Menu v-else as="div" class="relative inline-block text-left">
         <div>
          <MenuButton
           class="border-l-2 border-gray-500 pl-8 inline-flex w-full justify-center items-center text-base font-medium text-gray-800 hover:text-gray-900"
          >
           Account
           <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </MenuButton>
         </div>

         <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
         >
          <MenuItems
           class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
           <div class="px-4 py-3">
            <p class="text-xs text-gray-500 font-medium">Signed in as</p>
            <p class="truncate text-sm font-medium text-gray-900">
             {{ user.email }}
            </p>
           </div>
           <div class="px-4 py-3">
            <p class="text-xs text-gray-500 font-medium">Workspace</p>
            <div class="w-full relative inline-flex items-center">
             <select
              @change="setWorkspace($event)"
              class="rounded appearance-none block w-full bg-none border bg-gray-50 pl-2 py-1 text-sm text-gray-900"
             >
              <option>
               <span class="">{{ domainName }} </span>
              </option>
              <option
               v-for="workspace in filteredWorkspaces"
               :key="workspace.id"
               :value="workspace.id"
              >
               <span class="text-xs">{{ workspace.name }}</span>
              </option>
             </select>
             <div class="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <ChevronDownIcon :class="`h-3 w-3 text-gray-900`" aria-hidden="true" />
             </div>
            </div>
           </div>
           <div class="py-1">
            <MenuItem v-slot="{ active }">
             <a
              href="#"
              :class="[
               active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
               'block px-4 py-2 text-sm',
              ]"
              >Account settings</a
             >
            </MenuItem>

            <MenuItem v-slot="{ active }">
             <a
              href="#"
              :class="[
               active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
               'block px-4 py-2 text-sm',
              ]"
              >License</a
             >
            </MenuItem>
           </div>
           <div class="py-1">
            <form method="POST" @submit.prevent="signOut">
             <MenuItem v-slot="{ active }">
              <button
               type="submit"
               :class="[
                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                'block w-full px-4 py-2 text-left text-sm',
               ]"
              >
               Sign out
              </button>
             </MenuItem>
            </form>
           </div>
          </MenuItems>
         </transition>
        </Menu> -->

        <!-- <Popover class="relative" v-slot="{ open }">
                  <PopoverButton
                    :class="[
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
                    ]"
                  >
                    <span>More</span>
                    <ChevronDownIcon
                      :class="[
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500',
                      ]"
                      aria-hidden="true"
                    />
                  </PopoverButton>

                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 translate-y-1"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 translate-y-1"
                  >
                    <PopoverPanel
                      class="absolute left-1/2 z-10 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0"
                    >
                      <div
                        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden"
                      >
                        <div
                          class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8"
                        >
                          <a
                            v-for="item in resources"
                            :key="item.name"
                            :href="item.href"
                            class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                          >
                            <component
                              :is="item.icon"
                              class="flex-shrink-0 h-6 w-6 text-primary-600"
                              aria-hidden="true"
                            />
                            <div class="ml-4">
                              <p class="text-base font-medium text-gray-900">
                                {{ item.name }}
                              </p>
                              <p class="mt-1 text-sm text-gray-500">
                                {{ item.description }}
                              </p>
                            </div>
                          </a>
                        </div>
                        <div class="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                          <div>
                            <h3
                              class="text-sm tracking-wide font-medium text-gray-500 uppercase"
                            >
                              Recent Posts
                            </h3>
                            <ul role="list" class="mt-4 space-y-4">
                              <li
                                v-for="item in recentPosts"
                                :key="item.id"
                                class="text-base truncate"
                              >
                                <a
                                  :href="item.href"
                                  class="font-medium text-gray-900 hover:text-gray-700"
                                >
                                  {{ item.name }}
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div class="mt-5 text-sm">
                            <a
                              href="#"
                              class="font-medium text-primary-600 hover:text-primary-500"
                            >
                              View all posts
                              <span aria-hidden="true">&rarr;</span></a
                            >
                          </div>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover> -->
       </PopoverGroup>
       <!-- <div
                class="hidden md:flex items-center justify-end md:flex-1 lg:w-0"
              >
                <a
                  href="#"
                  class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Sign in
                </a>
                <a
                  href="#"
                  class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700"
                >
                  Sign up
                </a>
              </div> -->
      </div>
     </div>

     <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
     >
      <PopoverPanel
       focus
       class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
      >
       <div
        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
       >
        <div class="pt-5 pb-6 px-5">
         <div
          class="flex items-center justify-between"
         >
          <div>
           <img
            class="h-8 w-auto"
            src="./assets/cx-engine-logo-without-txt.svg"
            alt="Workflow"
           />
          </div>
          <div class="-mr-2">
           <PopoverButton
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
           >
            <span class="sr-only"
             >Close menu</span
            >
            <XMarkIcon
             class="h-6 w-6"
             aria-hidden="true"
            />
           </PopoverButton>
          </div>
         </div>
         <div class="mt-6">
          <nav class="grid gap-y-8">
           <a
            v-for="item in features"
            :key="item.name"
            :href="item.href"
            class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
           >
            <component
             :is="item.icon"
             class="flex-shrink-0 h-6 w-6 text-primary-600"
             aria-hidden="true"
            />
            <span
             class="ml-3 text-base font-medium text-gray-900"
            >
             {{ item.name }}
            </span>
           </a>
          </nav>
         </div>
        </div>
        <div
         class="py-6 px-5 space-y-6"
        >
         <div
          class="grid grid-cols-2 gap-y-4 gap-x-8"
         >
          <a
           href="#"
           class="text-base font-medium text-gray-900 hover:text-gray-700"
          >
           Pricing
          </a>

          <!-- <a
                    href="#"
                    class="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Docs
                  </a> -->
          <a
           v-for="item in resources"
           :key="item.name"
           :href="item.href"
           class="text-base font-medium text-gray-900 hover:text-gray-700"
          >
           {{ item.name }}
          </a>
         </div>
         <!-- <div>
                    <a
                      href="#"
                      class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary-600 hover:bg-primary-700"
                    >
                      Sign up
                    </a>
                    <p
                      class="mt-6 text-center text-base font-medium text-gray-500"
                    >
                      Existing customer?
                      <a href="#" class="text-primary-600 hover:text-primary-500">
                        Sign in
                      </a>
                    </p>
                  </div> -->
        </div>
       </div>
      </PopoverPanel>
     </transition>
    </Popover>
    <router-view
     @activeBanner="
      (activeBanner = true),
       (key = !key)
     "
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     @setLoading="isLoading = $event"
   :features="features"
    />
   </div>
  </div>
  <Footer />
 </div>
 <div v-else>
  <router-view
   @activeBanner="
    (activeBanner = true), (key = !key)
   "
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   @setLoading="isLoading = $event"
  />
 </div>
 <loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import SuccessBanner from "./components/SuccessBanner.vue";
import Footer from "./components/partials/Footer.vue";
import {
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import {
 Popover,
 PopoverButton,
 PopoverGroup,
 PopoverPanel,
} from "@headlessui/vue";
import {
 CalendarIcon,
 ChartBarIcon,
 CursorArrowRaysIcon,
 Bars3Icon,
 PhoneIcon,
 PlayIcon,
 ArrowPathIcon,
 ShieldCheckIcon,
 LifebuoyIcon,
 Squares2X2Icon,
 XMarkIcon,
 SignalIcon,
 UserPlusIcon,
} from "@heroicons/vue/24/outline";
import { mapGetters } from "vuex";
const features = [
 {
  id: 0,
  value: "stats",
  name: "Telco Expert Statistics",
  href:
   "/expert-statistics/the-use-case-for-expert-statistics",
  description:
   "Get a better understanding of your phone system traffic and usage.",
  icon: ChartBarIcon,
 },
 {
  id: 1,
  value: "smart",
  name: "Smart Routings",
  href:
   "/smart-routings/the-use-case-for-smart-routings",
  description:
   "A turn-key API, easy and ready to interact with your call flows or any kind of applications.",
  icon: SignalIcon,
 },
 {
  id: 2,
  value: "crm",
  name: "CRM Connectors",
  href:
   "/crm-connectors/the-use-case-for-crm-connectors",
  description:
   "Connect 3CX with third-party CRM that you're already using.",
  icon: Squares2X2Icon,
 },
 // {
 //   name: "Automations",
 //   href: "#",
 //   description: "Build strategic funnels that will drive your customers to convert",
 //   icon: ArrowPathIcon,
 // },
];
const callsToAction = [
 // {
 //   name: "New account",
 //   href: "#",
 //   icon: UserPlusIcon,
 // },
 //  { name: "Workspace", href: "/workspace", icon: OfficeBuildingIcon },
 {
  name: "Contact Us",
  href: "/contact-us",
  icon: PhoneIcon,
 },
];
const resources = [
 {
  name: "Help Center",
  description:
   "Get all of your questions answered in our contact support.",
  href: "/contact-us",
  icon: LifebuoyIcon,
 },
 // {
 //   name: "Guides",
 //   description:
 //     "Learn how to maximize our platform to get the most out of it.",
 //   href: "#",
 //   icon: BookmarkAltIcon,
 // },
 // {
 //   name: "Events",
 //   description:
 //     "See what meet-ups and other events we might be planning near you.",
 //   href: "#",
 //   icon: CalendarIcon,
 // },
 // {
 //   name: "Security",
 //   description: "Understand how we take your privacy seriously.",
 //   href: "#",
 //   icon: ShieldCheckIcon,
 // },
];
export default {
 components: {
  Popover,
  PopoverButton,
  PopoverPanel,
  PopoverGroup,
  ChevronDownIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  Bars3Icon,
  PhoneIcon,
  PlayIcon,
  ArrowPathIcon,
  ShieldCheckIcon,
  LifebuoyIcon,
  Squares2X2Icon,
  XMarkIcon,
  UserPlusIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  SuccessBanner,
  Footer,
 },
 data() {
  return {
   resources,
   callsToAction,
   features,
   key: false,
   successMsg: "",
   isSuccess: true,
   activeBanner: false,
   isLoading: false,
   appUrl:
    process.env.VUE_APP_APPLICATION_URL,
  };
 },
 methods: {},
 mounted() {},
 computed: {
  ...mapGetters([
   "token",
   "user",
   "workspaces",
   "domainName",
   "domainId",
  ]),
  filteredWorkspaces() {
   if (this.workspaces.length > 0) {
    return JSON.parse(
     this.workspaces
    ).filter((workspace) => {
     return (
      this.domainId !== workspace.id
     );
    });
   } else {
    return [];
   }
  },
 },
};
</script>

<style></style>
