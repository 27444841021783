import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import SmartRouting from "../views/SmartRouting.vue";
import ContactUs from "../views/ContactUs.vue";
import Workspace from "../views/Workspace.vue";
import ConfirmedEmail from "../views/ConfirmedEmail.vue";
import Usecases from "../views/Usecases.vue";

const routes = [
 {
  path: "/",
  name: "home",
  component: Home,
 },
 // {
 //   path: "/login",
 //   name: "login",
 //   component: Login,
 //   meta: {
 //     requiresNotLogged: true,
 //   },
 // },
 // {
 //   path: "/register",
 //   name: "register",
 //   component: Register,
 //   meta: {
 //     requiresNotLogged: true,
 //   },
 // },
 // {
 //   path: "/smart-routings/:summaryPath",
 //   name: "smartRouting",
 //   props: true,
 //   component: SmartRouting,
 // },
//  {
//   path: "/smart-routings-use-cases",
//   name: "usecases",
//   component: Usecases,
//  },
 {
  path: "/:parentPath/:contentPath",
  name: "smartRouting",
  props: true,
  component: SmartRouting,
 },
 {
  path: "/contact-us",
  name: "ContactUs",
  props: true,
  component: ContactUs,
 },
 {
  path: "/confirmation",
  name: "Confirmation",
  props: true,
  component: ConfirmedEmail,
 },
 // {
 //   path: "/workspace",
 //   name: "workspace",
 //   props: true,
 //   component: Workspace,
 //   meta: {
 //     requiresAuth: true,
 //   },
 // },
];

let entryUrl = null;

const router = createRouter({
 // mode: 'history',
 history: createWebHistory(process.env.BASE_URL),
 routes,
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresAuth)) {
  if (store.state.token) {
   if (entryUrl) {
    const url = entryUrl;
    entryUrl = null;
    return next(url);
   }
   next();
   return;
  }
  entryUrl = to.path;
  next("/login");
 } else {
  next();
 }
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresNotLogged)) {
  if (!store.state.token) {
   next();
   return;
  }
  next("/");
 } else {
  next();
 }
});

export default router;
