<template>
  <svg class="animated" id="freepik_stories-directions" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500" version="1.1" xmlns:svgjs="http://svgjs.com/svgjs">
    <g id="freepik--background-simple--inject-12" class="animable" style="transform-origin: 247.232px 250.609px;">
      <g id="elwl7ovabtnpr">
        <path
          d="M67.93,167.09s-27.88,72,11.3,144.5S199.11,422.39,263.7,455.14s131.66,16.8,163.53-36.84-11.84-91.93-11.9-168.42,11.45-96.74-30-161.36-143.06-78.93-219.9-31S67.93,167.09,67.93,167.09Z"
          style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 248.588px 250.609px;" class="animable"></path>
      </g>
      <path
        d="M127,444.09c23.6,24.91,81.54,15.79,106.63,0,81.46-51.26,224.25-136.57,230.71-260.17,2.75-52.54-43.57-80.12-62.93-88.22C355,76.27,304,70.43,253.62,68.18c-52.57-2.35-109-.52-156.31,25.13C79.38,103,62.46,115.49,50.88,132.5c-11.77,17.28-18.54,37.85-20.33,58.62-2.9,33.5,6.11,67.09,19.74,97.83s31.81,59.21,48.49,88.4C109.66,396.4,107.14,423.09,127,444.09Z"
        style="fill: rgb(79, 70, 229); transform-origin: 247.232px 263.484px;" id="elbtq05968r36" class="animable"></path>
      <g id="elodpyew8cyb">
        <path
          d="M127,444.09c23.6,24.91,81.54,15.79,106.63,0,81.46-51.26,224.25-136.57,230.71-260.17,2.75-52.54-43.57-80.12-62.93-88.22C355,76.27,304,70.43,253.62,68.18c-52.57-2.35-109-.52-156.31,25.13C79.38,103,62.46,115.49,50.88,132.5c-11.77,17.28-18.54,37.85-20.33,58.62-2.9,33.5,6.11,67.09,19.74,97.83s31.81,59.21,48.49,88.4C109.66,396.4,107.14,423.09,127,444.09Z"
          style="fill: rgb(255, 255, 255); opacity: 0.7; transform-origin: 247.232px 263.484px;" class="animable"></path>
      </g>
    </g>
    <g id="freepik--Buildings--inject-12" class="animable" style="transform-origin: 244.785px 258.235px;">
    <path d="M332.72,384.36c.28-86.36.28-182.36,0-268.14"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 332.825px 250.29px;"
      id="elzeszf5hfa09" class="animable"></path>
    <path d="M348.77,89.52c.23,95.48.23,179.48,0,274.93"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 348.856px 226.985px;"
      id="elmmd3yzebc4p" class="animable"></path>
    <path d="M332.72,95.74v-35a19.35,19.35,0,0,1,8-1.43c5.66,0,8,1.43,8,1.43v9.74"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 340.72px 77.5192px;"
      id="el7b1zeank3s7" class="animable"></path>
    <polyline points="433.43 59.3 436.73 60.64 415.56 78.64 348.77 89.52 345.61 88.58"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 391.17px 74.41px;"
      id="el14mbao464fs" class="animable"></polyline>
    <polygon points="345.61 71.17 410.22 56.93 433.43 59.3 413.49 77.15 345.61 88.58 345.61 71.17"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 389.52px 72.755px;"
      id="ely63gtnm626a" class="animable"></polygon>
    <polyline points="280.6 84.33 277.89 85.95 294.31 105.54 332.71 116.22 336.81 115.19"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 307.35px 100.275px;"
      id="elxy46t7e7qx" class="animable"></polyline>
    <polygon points="336.81 97.31 296.95 81.95 280.6 84.33 297.48 104.47 336.81 115.19 336.81 97.31"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 308.705px 98.57px;"
      id="el4vp01ke2i5t" class="animable"></polygon>
    <line x1="208.26" y1="108.49" x2="208.26" y2="449.44"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 208.26px 278.965px;"
      id="ele9ujg63l0b" class="animable"></line>
    <path d="M114.63,82.57C146,90,177,101,208.26,108.49"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 161.445px 95.53px;"
      id="elezjviihgvyh" class="animable"></path>
    <path d="M78.21,101.76C121,112,165,123,208.26,134"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 143.235px 117.88px;"
      id="elwc6ki2q0h7e" class="animable"></path>
    <path d="M50.22,124.93C102,137,155,148,208.26,159.43"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 129.24px 142.18px;"
      id="elo873jjjj4f" class="animable"></path>
    <path d="M34.22,152q86.67,16.5,174,32.89"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 121.22px 168.445px;"
      id="ele1gm2wd5ey8" class="animable"></path>
    <line x1="22" y1="180.61" x2="208.26" y2="210.36"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 115.13px 195.485px;"
      id="elsbf8q46l8wc" class="animable"></line>
    <line x1="22" y1="211.54" x2="208.26" y2="235.83"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 115.13px 223.685px;"
      id="elwcs31vesw88" class="animable"></line>
    <path d="M26.34,242.9C87,248,147,256,208.26,261.3"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 117.3px 252.1px;"
      id="eld3arstt8vl" class="animable"></path>
    <path d="M34.94,274.31C82,278,129,280,177,285c10,0,21,0,31.26,1.76"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 121.6px 280.535px;"
      id="el8upokrx9wwj" class="animable"></path>
    <path d="M46.59,305.35C101,308,154,311,208.26,312.23"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 127.425px 308.79px;"
      id="eli3od5h4vkkh" class="animable"></path>
    <path d="M61.15,335.74C110,336,159,337,208.26,337.7"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 134.705px 336.72px;"
      id="eldz5cn0qcdk" class="animable"></path>
    <path d="M78.13,365.25C121,365,165,364,208.26,363.17"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 143.195px 364.21px;"
      id="eldvaiqb3kbk" class="animable"></path>
    <path d="M93,393.85c38-1.85,77-3.85,115.29-5.22"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 150.645px 391.24px;"
      id="elzgpillgvumd" class="animable"></path>
    <line x1="196.49" y1="121.21" x2="196.49" y2="417.83"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 196.49px 269.52px;"
      id="elqkn9dt9fm6n" class="animable"></line>
    <line x1="140.51" y1="95.53" x2="140.51" y2="279.82"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 140.51px 187.675px;"
      id="elluxc1klkh4" class="animable"></line>
    <line x1="76.81" y1="120.4" x2="76.81" y2="324.06"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 76.81px 222.23px;"
      id="el7czdqxxsh98" class="animable"></line>
    <polyline points="460.62 127.76 362.79 139.06 362.79 368.39"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 411.705px 248.075px;"
      id="ela2tqj93a8w" class="animable"></polyline>
    <polyline points="467.57 157.21 375.79 164.82 375.79 348.05"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 421.68px 252.63px;"
      id="el76t3rau6qbc" class="animable"></polyline>
    <line x1="467.57" y1="201.9" x2="378.44" y2="208.26"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 423.005px 205.08px;"
      id="elzvs1xtme2jd" class="animable"></line>
    <line x1="454.38" y1="240.75" x2="381.07" y2="245.52"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 417.725px 243.135px;"
      id="el8wjhxno8q3k" class="animable"></line>
    <line x1="411.71" y1="168.71" x2="411.71" y2="309.37"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 411.71px 239.04px;"
      id="el5igvos2tkte" class="animable"></line>
    <path
      d="M115.74,427.46A63.16,63.16,0,0,0,127,444.09c23.6,24.91,82.55,15.79,107.64,0,8.25-5.19,16.12-10.74,25.42-16.63"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 187.9px 443.5px;"
      id="elmnpvpcohe3" class="animable"></path>
  </g>
  <g id="freepik--Map--inject-12" class="animable" style="transform-origin: 231.92px 280.702px;">
    <path
      d="M350.9,258.16,341.72,294l16,93.17-.12,0-.39.08c-6.81,1.49-80.17,17.45-83.76,17.15-3.76-.31-62.7-9.38-62.7-9.38l-52.17,6.1-41.83-18.45L78.1,389.49,67.1,253s8.17-66.74,4.41-84.71c0,0,64.37,9.24,70.32,9.88S217,157,217,157l58.41,19.35,48.93-6.19,72.4,33.9S349.73,250.86,350.9,258.16Z"
      style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 231.92px 280.702px;"
      id="elpnzw9qxbiq8" class="animable"></path>
    <path
      d="M79,174.16,141.83,183,216,164.74l59.35,16.61,46.57-5L386.44,204s-36.72,45.5-38.13,48.41S335.13,294,335.13,294l15.54,88.7-75.29,16.57-63.33-10.13-50.19,4.42-42.67-18.31-35.44,7.45L72.25,255.06S82.81,203.14,79,174.16Z"
      style="fill: rgb(79, 70, 229); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 229.345px 282.005px;"
      id="el78znxbrdj2q" class="animable"></path>
    <path
      d="M297.83,221.81,308,219.35l49.4,21.11c2.42-3.09,5.15-6.54,7.95-10.06L311.8,207.77l-11.28,3.16,7.07-33.07-9.35,1L289.66,214l-27.72,7.76-53.34,4.65L187.29,237l1-31.21,26.06-12.28,30.36,5.86,5.17-25.1-9.22-2.58-2.92,18.23-23.39-5-25.74,11.61.83-25.22L177,174.33v67.72l-31.61,15.7V182.12l-3.6.88-7.88-1.11,1.35,25.88H113.88L79.65,181c.22,3.86.25,8,.15,12.21l30.41,25.45h25.64l1,19.3-.94,8L72.87,262l.33,3.65,62.24-15.69L134,262.41,74.24,277.16l1.1,12.26L107,281.05l2.94,37.72L80.77,349.63l1.14,12.62,10.28-10.88,2.28,29.05,2.94-.62-2-31.83,21.29-22.52L127,322.82l40.77-13.15-.9,5.92L144.23,386l8.71,3.74,11.9-38.41,17.56-3.25,35.28,15.26,16.37,1.05-2.78,27.83,4.36.69,1.9-28.31,41.07,2.63,29.67-4.27,3.78,28.24,7.64-1.68L316,361.82l30.21-4.35-1.51-8.64-64.3,8.53-26-2.28,3.7-35,22.59-12.34h7l50.64,5.13-1.78-10.25-47.43-4.49H278.17l5.56-33.43Zm-168,89.1-17.2,5.1-2.28-35.87,26.5-7,39.52-20.4L169.86,296Zm55.78,27.33-17.83,3.54L175.18,318l11.19-70.39,22.53-11.63,8.75,35.48L214.38,301l-9.82,44.84Zm81.69-32.5-15.52,7.35-5.71,41.26-26-2.28-8.95-3.59,10-44.53L225,273.12h17.91l29.89-5Zm6.95-44.81-30,6.17H220.13l-7.26-31.75L259.94,231l26.58-6.46Z"
      style="fill: rgb(255, 255, 255); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 219.11px 282.11px;"
      id="eldd0ch4lqe2" class="animable"></path>
    <g id="eloaplxv3qg2h">
      <polygon points="286.52 289.5 289.23 268.09 323.83 275.69 321.01 293.97 286.52 289.5"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 305.175px 281.03px;" class="animable">
      </polygon>
    </g>
    <g id="els3mbydibb8">
      <polygon points="307.96 267.1 315.01 247.44 341.07 257.27 335.09 277.6 307.96 267.1"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 324.515px 262.52px;" class="animable">
      </polygon>
    </g>
    <g id="elb8ebqyiwozg">
      <polygon points="302.3 224.56 305.18 224.56 316.07 229.49 305.18 262.52 294.97 259.46 291.7 258.74 302.3 224.56"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 303.885px 243.54px;" class="animable">
      </polygon>
    </g>
    <g id="elv78245gyjqq">
      <polygon points="323.83 233.32 349.25 243.54 345.02 250.86 321.01 243.54 323.83 233.32"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 335.13px 242.09px;" class="animable"></polygon>
    </g>
    <g id="elbzcyhilr46">
      <polygon
        points="307.96 204.02 312.89 181.35 321.01 180.06 333.01 184.88 324.51 208.25 313.05 204.02 307.96 204.02"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 320.485px 194.155px;" class="animable">
      </polygon>
    </g>
    <g id="ellrcduma8c1n">
      <polygon points="329.83 210.7 335.13 197.66 370.79 213.9 363.73 224.56 329.83 210.7"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 350.31px 211.11px;" class="animable"></polygon>
    </g>
    <g id="elfyoxzrg8d5k">
      <polygon points="253.84 180.06 272.78 185.54 267.03 215.67 264.28 215.67 247.57 215.67 253.84 180.06"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 260.175px 197.865px;" class="animable">
      </polygon>
    </g>
    <g id="eleu156sgwzn">
      <polygon points="277.58 186.37 291.7 183.97 286.52 210.7 272.78 214.26 277.58 186.37"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 282.24px 199.115px;" class="animable">
      </polygon>
    </g>
    <g id="el4ip3pk4cue">
      <polygon points="243.69 204.02 241.98 217.08 211.19 218.49 192.68 228.03 192.68 210.7 215.09 199.12 243.69 204.02"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 218.185px 213.575px;" class="animable">
      </polygon>
    </g>
    <g id="elx6inl6duroh">
      <polygon points="194.02 174.16 216.26 168.31 214.54 181.35 192.72 189.88 194.02 174.16"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 204.49px 179.095px;" class="animable">
      </polygon>
    </g>
    <g id="elwplpd2odd2h">
      <polygon points="219.09 181.35 235.21 184.88 237.78 172.95 221.09 170.12 219.09 181.35"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 228.435px 177.5px;" class="animable"></polygon>
    </g>
    <g id="el1hzg49m5ww7">
      <polygon points="149.42 184.88 149.42 204.02 172.72 197.66 172.72 180.06 149.42 184.88"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 161.07px 192.04px;" class="animable"></polygon>
    </g>
    <g id="elyv58aqyzdtl">
      <polygon
        points="151.02 211.11 151.02 248.94 163.89 243.54 161.86 220.26 172.72 217.08 172.72 204.02 151.02 211.11"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 161.87px 226.48px;" class="animable"></polygon>
    </g>
    <g id="elkinlzywvima">
      <polygon points="111.99 223.08 113.76 247.44 130.7 243.54 133.42 235.94 133.42 223.08 111.99 223.08"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 122.705px 235.26px;" class="animable">
      </polygon>
    </g>
    <g id="elul73ef8eie">
      <path d="M84.46,204l22.35,17.3v12l-25.18,1.94S84.81,214.26,84.46,204Z"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 94.22px 219.62px;" class="animable"></path>
    </g>
    <g id="elpea487pura9">
      <polygon points="81.63 240.03 104.66 237.91 104.66 249.53 77.58 256.92 81.63 240.03"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 91.12px 247.415px;" class="animable"></polygon>
    </g>
    <g id="elwi3poikpb1j">
      <polygon points="111.99 183.97 111.99 200.49 130.7 201.9 129.93 184.88 111.99 183.97"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 121.345px 192.935px;" class="animable">
      </polygon>
    </g>
    <g id="elyoy1jqj2lii">
      <polygon points="190.29 250.86 206.36 243.37 211.19 270.76 187.08 275 190.29 250.86"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 199.135px 259.185px;" class="animable">
      </polygon>
    </g>
    <g id="el4z8f8bmi2eu">
      <polygon points="194.02 279.17 192.72 297.93 209.44 296.17 209.44 275 194.02 279.17"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 201.08px 286.465px;" class="animable">
      </polygon>
    </g>
    <g id="elfjujoxv5uq4">
      <polygon points="241.98 237.91 239.8 262.52 248.89 262.52 269.11 259.18 280.76 229.49 258.56 235.94 241.98 237.91"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 260.28px 246.005px;" class="animable">
      </polygon>
    </g>
    <g id="elbj0v9vpmghw">
      <polygon points="219.09 239.32 219.09 246 235.92 245.52 235.92 237.91 219.09 239.32"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 227.505px 241.955px;" class="animable">
      </polygon>
    </g>
    <g id="el0ny8gfpfrspk">
      <polygon points="243.69 282.07 241.98 301.82 264.17 297.93 267.03 275 243.69 282.07"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 254.505px 288.41px;" class="animable">
      </polygon>
    </g>
    <g id="el4lwb6surrcx">
      <polygon points="224.27 307.77 239.8 308.88 235.92 348.51 221.02 348.05 215.44 346.04 224.27 307.77"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 227.62px 328.14px;" class="animable"></polygon>
    </g>
    <g id="el4mn6e3hqyy">
      <polygon points="229.34 279.17 239.8 280.68 237.78 293.97 227.5 293.97 229.34 279.17"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 233.65px 286.57px;" class="animable"></polygon>
    </g>
    <g id="eltnfguuruf5c">
      <polygon points="312.89 316.01 333.01 317.96 337.6 342.2 312.89 342.71 312.89 316.01"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 325.245px 329.36px;" class="animable">
      </polygon>
    </g>
    <g id="elo52p7z85hp">
      <polygon points="267.03 324.06 286.52 322.49 289.23 350.89 263.81 350.89 267.03 324.06"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 276.52px 336.69px;" class="animable"></polygon>
    </g>
    <g id="elfjo7ia7fmov">
      <polygon points="321.01 367.19 323.83 380.17 337.6 377.93 335.13 364.43 321.01 367.19"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 329.305px 372.3px;" class="animable"></polygon>
    </g>
    <g id="elwvufp1kf32">
      <polygon points="276.52 372.3 303.88 368.97 305.18 384.08 275.38 391.47 258.52 389.49 264.4 370.9 276.52 372.3"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 281.85px 380.22px;" class="animable"></polygon>
    </g>
    <g id="el36cl9fqe0vn">
      <polygon points="134.94 328.14 157.19 324.06 155.69 339.42 149.42 354.4 133.42 358.66 134.94 328.14"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 145.305px 341.36px;" class="animable">
      </polygon>
    </g>
    <g id="el37zjq36os96">
      <polygon points="115.53 284.36 115.53 296.52 132.66 293.97 141.83 290.44 141.36 275 135.12 279.17 115.53 284.36"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 128.68px 285.76px;" class="animable"></polygon>
    </g>
    <g id="el0kdl6tzpadsj">
      <polygon points="79.86 293.97 102.46 288.41 104.66 311.76 83.04 316.01 79.86 293.97"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 92.26px 302.21px;" class="animable"></polygon>
    </g>
    <g id="el51vmw8s0w55">
      <polygon points="81.44 335.48 94.34 324.06 97.69 327.16 84.35 339.97 81.44 335.48"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 89.565px 332.015px;" class="animable">
      </polygon>
    </g>
    <g id="elpy58lap09sd">
      <polygon points="84.81 367.19 91.12 365.7 92.26 378.43 86.01 379.34 84.81 367.19"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 88.535px 372.52px;" class="animable"></polygon>
    </g>
    <g id="elfbqnhkdxv1l">
      <polygon points="101.4 374.91 100.19 352.66 115.53 350.89 115.53 372.88 101.4 374.91"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 107.86px 362.9px;" class="animable"></polygon>
    </g>
    <g id="el7uqkgpd2dps">
      <circle cx="198.04" cy="315.43" r="8.63"
        style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 198.04px 315.43px;" class="animable"></circle>
    </g>
    <g id="el2pm4jj9dwr2">
      <path
        d="M350.9,258.16,341.72,294l16,93.17-.12,0-.39.08L273.46,404.4l21.77-146.72,29.08-87.56,72.4,33.9S349.73,250.86,350.9,258.16Z"
        style="fill-opacity: 0.7; opacity: 0.1; transform-origin: 335.085px 287.26px;" class="animable"></path>
    </g>
    <g id="elgq0wntngps">
      <polygon
        points="275.38 176.31 253.22 254.86 233.92 288.98 210.76 395.02 158.59 401.12 197.01 311.77 206.15 244.97 217.73 157.21 275.38 176.31"
        style="fill-opacity: 0.7; opacity: 0.1; transform-origin: 216.985px 279.165px;" class="animable"></polygon>
    </g>
    <g id="el8ublrsob42r">
      <path d="M141.36,178.14l-1.59,73-21.9,132.06-1.11-.49L78.1,389.49,68,263.72l60.51-23.4,10-62.55Z"
        style="fill-opacity: 0.7; opacity: 0.1; transform-origin: 104.68px 283.63px;" class="animable"></path>
    </g>
  </g>
  <g id="freepik--Directions--inject-12" class="animable" style="transform-origin: 200.555px 223.928px;">
    <path
      d="M292.66,217.68a1.32,1.32,0,0,0-1.29-.36L261,225.15l-51.67,3.9a1.29,1.29,0,0,0-.51.14L141,264l1-22V212.27a1.34,1.34,0,0,0-1.34-1.34H127a1.34,1.34,0,1,0,0,2.68h12.26V241.9l-1.08,24.27a1.32,1.32,0,0,0,.61,1.19,1.35,1.35,0,0,0,1.33.07l69.67-35.73,51.52-3.89a.85.85,0,0,0,.23,0l28.22-7.28-12.15,43a1,1,0,0,0,0,.14l-2,11.88-1.14,0a.41.41,0,0,0-.4.5l1.69,4.69a.47.47,0,0,0,.83.18l3.16-4.57c.2-.28,0-.62-.43-.64l-1,0,1.89-11.46L293,219A1.32,1.32,0,0,0,292.66,217.68Z"
      style="fill: rgb(79, 70, 229); transform-origin: 209.358px 246.017px;" id="elxurnup30eqf" class="animable"></path>
    <g id="elcf3116i96ag">
      <path
        d="M292.66,217.68a1.32,1.32,0,0,0-1.29-.36L261,225.15l-51.67,3.9a1.29,1.29,0,0,0-.51.14L141,264l1-22V212.27a1.34,1.34,0,0,0-1.34-1.34H127a1.34,1.34,0,1,0,0,2.68h12.26V241.9l-1.08,24.27a1.32,1.32,0,0,0,.61,1.19,1.35,1.35,0,0,0,1.33.07l69.67-35.73,51.52-3.89a.85.85,0,0,0,.23,0l28.22-7.28-12.15,43a1,1,0,0,0,0,.14l-2,11.88-1.14,0a.41.41,0,0,0-.4.5l1.69,4.69a.47.47,0,0,0,.83.18l3.16-4.57c.2-.28,0-.62-.43-.64l-1,0,1.89-11.46L293,219A1.32,1.32,0,0,0,292.66,217.68Z"
        style="fill-opacity: 0.7; opacity: 0.6; transform-origin: 209.358px 246.017px;" class="animable"></path>
    </g>
    <path
      d="M133,164.14h0a14.61,14.61,0,0,0-20.67,0h0a14.63,14.63,0,0,0,0,20.67l10.34,10.33L133,184.81A14.61,14.61,0,0,0,133,164.14ZM127,178.73h0a6,6,0,1,1,0-8.51h0A6,6,0,0,1,127,178.73Z"
      style="fill: rgb(38, 50, 56); transform-origin: 122.669px 177.498px;" id="elssdye8rocd" class="animable"></path>
    <path d="M112.34,180.88a18.24,18.24,0,0,0,4.81,6.07"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 114.745px 183.915px;"
      id="eledkz5kx6a5i" class="animable"></path>
    <path d="M115.89,164c-5,3-6.3,8.79-4.66,14.12"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 113.225px 171.06px;"
      id="elxbqfc9593nh" class="animable"></path>
    <path d="M122.71,162.06a13.12,13.12,0,0,0-3.73.56"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 120.845px 162.34px;"
      id="el3yjdo4l9yie" class="animable"></path>
    <path
      d="M264.84,257h0a14.61,14.61,0,0,0-20.67,0h0a14.61,14.61,0,0,0,0,20.67L254.5,288l10.34-10.33A14.61,14.61,0,0,0,264.84,257Zm-6.08,14.59h0a6,6,0,1,1,0-8.51h0A6,6,0,0,1,258.76,271.59Z"
      style="fill: rgb(38, 50, 56); transform-origin: 254.505px 270.358px;" id="ela9q76q17o1" class="animable"></path>
    <path d="M244.14,273.74a18.25,18.25,0,0,0,4.81,6.08"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 246.545px 276.78px;"
      id="el505tn1uhq8i" class="animable"></path>
    <path d="M247.69,256.85c-5,3-6.3,8.78-4.66,14.12"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 245.025px 263.91px;"
      id="el19qcbpfcyol" class="animable"></path>
    <path d="M254.5,254.92a13.35,13.35,0,0,0-3.72.56"
      style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; transform-origin: 252.64px 255.2px;"
      id="eljvj61mjc7dj" class="animable"></path>
  </g>
  <g id="freepik--Character--inject-12" class="animable" style="transform-origin: 208.749px 352.617px;">
    <path
      d="M350.9,258.15c-1.18.48-11.06,1.65-11.06,1.65.23-2.35,5.18-6.59,5.18-8.94A1.69,1.69,0,0,0,342.9,249c-.47,3.06-8.24,8.7-9.89,9.41s-6.12,15.77-8.54,18.13c-19,18.94-37,29.89-43.71,43a53.19,53.19,0,0,1-18.36,19.91s-3.88,49.94,10.94,27.75,20.13-20.89,27.54-30.77S322.77,304,334.07,285c5.77-4.9,10.24-2.63,13.77-6.16.75-.75.24-3.77,1.41-5.75s3.06-5.55,2.83-7.67,1.65-3.29,1.88-4.47S352.08,257.68,350.9,258.15Z"
      style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-miterlimit: 10; transform-origin: 307.96px 310.912px;"
      id="elccy9rilq2no" class="animable"></path>
    <path
      d="M123,327.58c-3.84-14.23-19.2-28.66-33.86-51.12C87.27,273.65,86,258,84.55,257s-7.9-8.16-7.73-11.25a1.69,1.69,0,0,0-2.46,1.4c-.49,2.3,3.47,7.47,3.22,9.82,0,0-9.43-3.19-10.49-3.9s-3.56.93-3.57,2.13,1.58,2.74.91,4.77.44,5.91,1.18,8.09-.38,5,.19,5.91c2.73,4.19,7.57,2.89,12.2,8.88,7.14,20.84,16.63,45.93,21.84,57.14s10.66,11,20.58,35.81,16.45-24.89,16.45-24.89A53.22,53.22,0,0,1,123,327.58Z"
      style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-miterlimit: 10; transform-origin: 100.195px 314.151px;"
      id="el3j7832zpg75" class="animable"></path>
    <path
      d="M129.24,334.35s2.56-6.08-2.22-8-10.79,1.55-10.79,1.55-8.83,2.21-9.18,11.55c0,0-2,31.34,5.36,31.46S129.24,334.35,129.24,334.35Z"
      style="fill: rgb(153, 153, 153); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 118.363px 348.341px;"
      id="el7i1ff5vujhv" class="animable"></path>
    <path d="M267,335.44s-.39-4.92,5.61-4.27,10.94,9.2,10.59,21.17c0,0,2.75,15.27-4.63,14.85S267,335.44,267,335.44Z"
      style="fill: rgb(153, 153, 153); stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 275.348px 349.155px;"
      id="ely522ist5fu" class="animable"></path>
    <path
      d="M151.19,362.49S130.74,338.66,127,330.42c0,0-14.08-.46-16,11.89a95,95,0,0,0,0,24.88s13.88,37.88,19.65,52.22Z"
      style="fill: rgb(117, 117, 117); transform-origin: 130.686px 374.914px;" id="ela1wwbjahuhw" class="animable">
    </path>
    <g style="clip-path: url(&quot;#freepik--clip-path--inject-12&quot;); transform-origin: 127.109px 374.93px;"
      id="elnqxupor1sdf" class="animable">
      <g id="el6pjc7mn8z6t">
        <g style="opacity: 0.3; transform-origin: 130.48px 372.675px;" class="animable">
          <path
            d="M144,353.86a26,26,0,0,0-9.44,3.18c-.77.42-1.55.89-2.33,1.42a11.92,11.92,0,0,0-2,1.69c-3,3-5,7.53-6.25,12.86a69.94,69.94,0,0,0-1.44,8.36,114.86,114.86,0,0,0-.68,14.83c-1.7-4.51-3.38-9-4.9-13.11h0a79.81,79.81,0,0,1,1.34-9.14h0a50.35,50.35,0,0,1,2-7.13,27,27,0,0,1,6.9-11.14,16.48,16.48,0,0,1,2.08-1.64c.78-.52,1.56-1,2.32-1.44a32.66,32.66,0,0,1,8.63-3.45Z"
            style="fill: rgb(79, 70, 229); transform-origin: 130.48px 372.675px;" id="el7lbp9lhbzcx" class="animable">
          </path>
        </g>
      </g>
      <g id="elrbv845xffpq">
        <g style="opacity: 0.3; transform-origin: 123.245px 356.25px;" class="animable">
          <path
            d="M136.14,344a62.37,62.37,0,0,0-8.09,2.94c-.91.4-1.84.84-2.81,1.31a14,14,0,0,0-2,1.18c-3.33,2.4-5.64,6.26-7.25,10.67a50.16,50.16,0,0,0-1.91,7.06c-.39,2-.68,4-.91,5.94l-2.16-5.88s-.34-2.28-.59-5.84c0-.36,0-.73-.07-1.11a43.45,43.45,0,0,1,2.16-5.55,24.65,24.65,0,0,1,8.09-10,18.2,18.2,0,0,1,2.24-1.3c.86-.43,1.71-.82,2.53-1.19a70.6,70.6,0,0,1,7.31-2.83C133.79,340.89,134.94,342.42,136.14,344Z"
            style="fill: rgb(79, 70, 229); transform-origin: 123.245px 356.25px;" id="elqb6e6ck0no" class="animable">
          </path>
        </g>
      </g>
      <g id="el7rxffux99lk">
        <g style="opacity: 0.3; transform-origin: 119.745px 338.19px;" class="animable">
          <path
            d="M128.88,333.78a29.3,29.3,0,0,0-6.56,2.59,33.61,33.61,0,0,0-4.56,2.91,46.89,46.89,0,0,0-7.15,6.65c.11-1.2.26-2.41.44-3.61a12.83,12.83,0,0,1,4.1-7.87s0,0,0,0l.13-.08a39.18,39.18,0,0,1,3.32-2.14c.42-.25.85-.48,1.28-.71l.08.16a19.3,19.3,0,0,1,2.4-.7,20.51,20.51,0,0,1,4.63-.53A30.67,30.67,0,0,0,128.88,333.78Z"
            style="fill: rgb(79, 70, 229); transform-origin: 119.745px 338.19px;" id="ell65xp5z629" class="animable">
          </path>
        </g>
      </g>
      <g id="elmpopfodjoe8">
        <g style="opacity: 0.3; transform-origin: 128.225px 350.24px;" class="animable">
          <path
            d="M141.16,365.4l-4,3.58a95.93,95.93,0,0,1-7-8.83c-1.05-1.47-2.06-3-3.05-4.49-1.33-2.06-2.62-4.15-3.85-6.25q-1.36-2.34-2.64-4.68c-1-1.83-2-3.66-2.88-5.45s-1.68-3.34-2.45-4.94h0a14.64,14.64,0,0,1,3.33-2.13c.42-.25.85-.48,1.28-.71l.08.16c.74,1.53,1.51,3.11,2.33,4.72,1,1.92,2,3.89,3.09,5.87q1.28,2.34,2.64,4.68,1.68,2.85,3.48,5.66,1.47,2.28,3,4.46A93,93,0,0,0,141.16,365.4Z"
            style="fill: rgb(79, 70, 229); transform-origin: 128.225px 350.24px;" id="el3a15k0p1qdy" class="animable">
          </path>
        </g>
      </g>
      <g id="el5jw83tmy1gr">
        <g style="opacity: 0.3; transform-origin: 123.109px 375.63px;" class="animable">
          <path
            d="M136,398.49,130.85,400c-1.53-5.21-4.6-11.74-8.36-18.63-1.33-2.45-2.76-4.94-4.24-7.43h0c-1.34-2.28-2.73-4.55-4.13-6.79-1.21-2-2.43-3.9-3.66-5.78,0-.36,0-.73-.07-1.11-.15-2.57-.23-5.66-.12-9l2.28,3.45c1.14,1.74,2.31,3.55,3.48,5.38,1.4,2.2,2.81,4.45,4.2,6.72,1.26,2.06,2.5,4.14,3.7,6.21C129.29,382.25,133.92,391.43,136,398.49Z"
            style="fill: rgb(79, 70, 229); transform-origin: 123.109px 375.63px;" id="elmawek3ofceo" class="animable">
          </path>
        </g>
      </g>
      <g id="el2ny7sc54phx">
        <path
          d="M135.58,405.86l-4.88,13.55c-5.77-14.34-19.65-52.21-19.65-52.21a95,95,0,0,1,0-24.88c1.17-7.68,7-10.41,11.34-11.37a6.91,6.91,0,0,1,.76.17s-11.14,7.13-9.92,34.58l10.85,30s1.14-10.45,5.48-8.94C131.43,387.43,134.09,396.1,135.58,405.86Z"
          style="fill-opacity: 0.7; opacity: 0.2; transform-origin: 122.906px 375.18px;" class="animable"></path>
      </g>
    </g>
    <path
      d="M151.19,362.49S130.74,338.66,127,330.42c0,0-14.08-.46-16,11.89a95,95,0,0,0,0,24.88s13.88,37.88,19.65,52.22Z"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 130.686px 374.914px;"
      id="elhuox142iuk" class="animable"></path>
    <path
      d="M195.22,341.21c-.1.41-.51.75-1.2,1a16.72,16.72,0,0,1-6,.44c-12.75-.7-38.62-8.27-44.79-23.33-6.59-16.07,10.32-37.05,28.42-37.5,10.4-.25,19.17,12,21.28,26.44C194,315.22,199.91,321,195.22,341.21Z"
      style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-miterlimit: 10; transform-origin: 169.39px 312.282px;"
      id="elqas3axju0t" class="animable"></path>
    <path d="M186.38,318a7,7,0,0,0-.71,4.31"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 185.984px 320.155px;"
      id="el7jn8uyn2ooe" class="animable"></path>
    <path d="M186.48,326.34s1.85,2.5,3.18,1.34"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 188.07px 327.164px;"
      id="elcfb40f5s4g" class="animable"></path>
    <path
      d="M194,342.21a16.72,16.72,0,0,1-6,.44c.26-1.89.76-4.08.92-5.18.52-3.47-1.22-4.55-.48-4.8s.67,2.84,2.14,4.41A22.78,22.78,0,0,1,194,342.21Z"
      style="fill: rgb(38, 50, 56); transform-origin: 191px 337.701px;" id="eltecyzdy9r" class="animable"></path>
    <path
      d="M189,339.57s-2.27,8.7-2,13.11a55.84,55.84,0,0,0,1.83,10l-27.46,1.59s4.11-19.77,1.91-26.69,17.56-4.4,17.56-4.4"
      style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-miterlimit: 10; transform-origin: 175.185px 348.501px;"
      id="elmy3br7nov" class="animable"></path>
    <path
      d="M185.86,298.76c1.15-3.74,3.2-3.36,3.2-3.36s-1.33-17.95-9-19.86c-5.18-1.3-6.6,6.5-12,.88-3-3.15-8.48-4-12.15-1.34-2.17,1.6-2.1,3.83-3.55,5.77s-4.44,1.86-6.64,2.31c-3.4.69-6.85,3.22-7.56,6.8-.58,2.92,3,5.23,2.3,7.63-.83,2.87-3.5,4.61-5.15,7-2,2.86-2.18,6.58-1.41,9.9,1.15,4.94,3.7,6.87,7.67,9.39,2,1.26,3.17,3.55,4.4,5.48,4.12,6.48,9.12,11.22,17,12.56,15.81,2.7,22.89-11.89,22.89-11.89s-1.78-14.46-.49-16.11,4,4,4,4C192.66,309.12,185.86,298.76,185.86,298.76Z"
      style="fill: rgb(38, 50, 56); transform-origin: 161.873px 307.874px;" id="elbo7ne7pahn6" class="animable"></path>
    <path d="M189.53,320.15c-.48-1.76-.14-9-2.33-8.34-1.92.59-1,8.06,1,13.05s4.45,5,4.52,3.55-1.59-2.36-2-4.45"
      style="fill: rgb(191, 191, 191); stroke: rgb(38, 50, 56); stroke-miterlimit: 10; transform-origin: 189.433px 320.506px;"
      id="ell3gc6twfbcm" class="animable"></path>
    <g id="elgf23wm4urmr">
      <path
        d="M184.73,289.78s.87-8.25-4.7-8-5.28,8-5.28,8-4.68-1.59-6.28,1.11.05,8.16.05,8.16-5,1.59-3.77,4.94-2,3.85-2,3.85"
        style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; opacity: 0.3; transform-origin: 173.765px 294.807px;"
        class="animable"></path>
    </g>
    <g id="elywpds68lwv">
      <path d="M183.46,296.52s-.88,7.3-6.09,7.47c0,0-5.36,16.48-23.57,16.13"
        style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; opacity: 0.3; transform-origin: 168.63px 308.323px;"
        class="animable"></path>
    </g>
    <g id="elycnmcl7y7f">
      <path
        d="M169.51,280.68s-4.2-3.93-9.25-1.52-1.69,9.25-1.69,9.25-4.9-.3-6.51,3.4,3.16,6.12,3.16,6.12-5.44,2.89-3.16,5.18,0,6.89,0,6.89"
        style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; opacity: 0.3; transform-origin: 160.506px 294.189px;"
        class="animable"></path>
    </g>
    <g id="elyh1jnungq8n">
      <path
        d="M148.51,288.41s-7.62,2.4-6,8.11,2.9,5.69,2.9,5.69-5.71,2-6.06,6.11,4.59,3.94,4.59,3.94-1.59,4.14,2.47,5.7"
        style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; opacity: 0.3; transform-origin: 143.921px 303.185px;"
        class="animable"></path>
    </g>
    <path
      d="M151.19,360s18.39-13,51.86.66l25.11-5.86s16.24,44.11,6.5,89.32c0,0-21.79,15.63-58.27,15.55-39.57-.1-49.37-15.55-49.37-15.55l5.66-14.43c-3.41-16.48-7.82-42.9-4.46-56.58C133.33,352.31,151.19,360,151.19,360Z"
      style="fill: rgb(117, 117, 117); transform-origin: 182.396px 407.042px;" id="elqrvjoa9fo6n" class="animable">
    </path>
    <g style="clip-path: url(&quot;#freepik--clip-path-2--inject-12&quot;); transform-origin: 182.395px 406.991px;"
      id="ele3v9swgw7c" class="animable">
      <g id="eluab95g7cuua">
        <g style="opacity: 0.3; transform-origin: 222.023px 403.495px;" class="animable">
          <path
            d="M226.34,448.79c-1.52.74-3.26,1.52-5.18,2.32-.16-1.91-.32-3.8-.46-5.67s-.29-3.8-.42-5.67c-.42-5.66-.77-11.08-1.07-16.23q-.17-2.94-.31-5.77-.6-11.58-.87-21.3-.09-3.15-.16-6.09-.21-10.32-.15-18.24c0-2.08,0-4,.06-5.88q.09-5.22.24-9.12l5.39-1.26c-.08,2.26-.17,4.91-.23,7.95h0c0,1.87-.07,3.87-.09,6,0,5,0,10.87.09,17.52q0,3,.13,6.27.25,9.86.84,21.79c.1,1.9.19,3.85.3,5.83q.45,7.74,1,16.28.21,2.81.43,5.7C226.05,445.07,226.19,446.91,226.34,448.79Z"
            style="fill: rgb(79, 70, 229); transform-origin: 222.023px 403.495px;" id="el1if0ory7gju" class="animable">
          </path>
        </g>
      </g>
      <g id="el1wp46hiei6w">
        <g style="opacity: 0.3; transform-origin: 205.903px 407.875px;" class="animable">
          <path
            d="M210.45,358.91c-.4,2.95-.82,6.48-1.24,10.54-.2,1.84-.4,3.8-.58,5.86-.53,5.88-1,12.6-1.38,20-.09,1.94-.18,3.93-.24,6-.24,6.61-.36,13.69-.28,21.16,0,1.88,0,3.78.08,5.7q.16,8,.64,16.6c.11,1.84.22,3.7.36,5.58q.16,2.58.39,5.19c-1.69.46-3.45.9-5.28,1.3-.13-1.59-.26-3.17-.36-4.74-.14-1.86-.26-3.71-.37-5.54-.35-5.76-.57-11.37-.69-16.81-.06-1.89-.09-3.75-.11-5.6-.09-7.39,0-14.41.2-21q.09-3,.22-5.81c.32-7.53.79-14.4,1.31-20.47.18-2,.35-3.91.53-5.73.42-4.13.84-7.79,1.23-10.91Z"
            style="fill: rgb(79, 70, 229); transform-origin: 205.903px 407.875px;" id="eldem9iy00xi" class="animable">
          </path>
        </g>
      </g>
      <g id="el848p281t1dn">
        <g style="opacity: 0.3; transform-origin: 187.645px 407.58px;" class="animable">
          <path
            d="M192.53,357c-.54,4.26-1.18,10-1.82,16.82q-.26,2.65-.49,5.52c-.52,6.35-1,13.49-1.4,21.21-.09,1.82-.18,3.66-.25,5.54-.27,6.66-.43,13.7-.46,21q0,2.7,0,5.46c0,5.8.16,11.76.41,17.81.07,1.81.15,3.62.25,5.45,0,1.06.11,2.11.18,3.17-1.74.17-3.52.32-5.34.41-.05-.83-.1-1.66-.14-2.48-.1-1.81-.19-3.61-.26-5.41-.27-6.23-.41-12.36-.45-18.34,0-1.81,0-3.6,0-5.39,0-7.31.18-14.35.44-21,.07-1.85.14-3.66.23-5.45.37-7.73.85-14.91,1.38-21.32.15-1.88.31-3.7.47-5.44.71-7.78,1.44-14.18,2-18.79C189,356.13,190.72,356.54,192.53,357Z"
            style="fill: rgb(79, 70, 229); transform-origin: 187.645px 407.58px;" id="el3knm5r0uo4" class="animable">
          </path>
        </g>
      </g>
      <g id="elobk1c2oc8gf">
        <g style="opacity: 0.3; transform-origin: 168.995px 407.005px;" class="animable">
          <path
            d="M173.57,459.6c-1.87,0-3.68-.11-5.42-.23,0-.3-.07-.61-.1-.93-.18-1.74-.33-3.53-.45-5.38-.42-6.24-.55-13-.68-20.27,0-.3,0-.58,0-.88,0-1.49-.05-3-.08-4.56-.12-6.51-.3-13.45-.71-20.78-.1-1.78-.21-3.6-.34-5.43-.18-2.59-.39-5.21-.64-7.88-.45-4.74-.68-9-.73-12.94,0-1.9,0-3.69.06-5.38a76.52,76.52,0,0,1,3-20.17,56.5,56.5,0,0,1,5.77-.36c-1.43,3.92-3.07,10.55-3.39,20.82-.05,1.69-.07,3.47,0,5.36q.06,5.49.68,12.16.43,4.53.73,8.9c.12,1.82.23,3.61.32,5.38.39,7.41.56,14.41.68,21,0,1.3,0,2.57.07,3.83,0,.52,0,1,0,1.55.13,7,.26,13.61.68,19.61.12,1.84.27,3.62.47,5.34C173.46,458.76,173.52,459.18,173.57,459.6Z"
            style="fill: rgb(79, 70, 229); transform-origin: 168.995px 407.005px;" id="elqn7rbi353hj" class="animable">
          </path>
        </g>
      </g>
      <g id="elyh1a1a12m2">
        <g style="opacity: 0.3; transform-origin: 153.285px 407.175px;" class="animable">
          <path
            d="M155.68,379.13a98.57,98.57,0,0,0-.07,10.44c.17,3.33.24,6.49.24,9.54,0,1.89,0,3.72-.07,5.54-.16,6.2-.56,12.08-1,18.14,0,.54-.07,1.09-.11,1.64-.13,1.82-.25,3.66-.36,5.53-.32,5-.57,10.21-.63,15.82,0,1.5,0,3,0,4.36q.06,3.08.23,5.83c0,.55.06,1.1.11,1.63q-2.92-.57-5.46-1.28c-.05-.8-.09-1.62-.13-2.47-.09-1.94-.13-4-.14-6.11,0-.66,0-1.34,0-2,.06-6.27.36-12,.71-17.51.13-1.9.25-3.76.38-5.61,0-.06,0-.13,0-.2.45-6.47.88-12.71,1-19.36,0-1.86,0-3.75,0-5.69,0-2.42-.09-4.92-.22-7.52a105.82,105.82,0,0,1,.11-11.93c.1-1.83.25-3.65.41-5.46.44-4.67,1.07-9.21,1.74-13.28a33.24,33.24,0,0,1,5-2.16h.05l0,0h0q.39-.15.81-.27c-.89,5-1.79,10.91-2.34,17C155.9,375.52,155.77,377.33,155.68,379.13Z"
            style="fill: rgb(79, 70, 229); transform-origin: 153.285px 407.175px;" id="eln6uwx6g9ab" class="animable">
          </path>
        </g>
      </g>
      <g id="el0no19g0yfsm8">
        <g style="opacity: 0.3; transform-origin: 145.635px 404.86px;" class="animable">
          <path
            d="M149,359.25c.76.19,1.37.4,1.74.53a17.41,17.41,0,0,0-7.4,10,22.09,22.09,0,0,0-.85,5.45,29.51,29.51,0,0,0,.09,3.84q.68,7.83.86,15.12c.07,2,.1,4,.1,6a186.81,186.81,0,0,1-.93,19.46q-.27,2.85-.66,5.63c-.14,1.07-.29,2.14-.46,3.2-.63,4-1.25,8.84-1.76,13.77-.21,2.09-.41,4.19-.58,6.27q-.17,2.13-.3,4.2a41.32,41.32,0,0,1-5.17-2.83c.13-1.73.29-3.46.45-5.13.22-2.32.46-4.54.7-6.57.7-6.05,1.36-10.42,1.38-10.54q.36-2.35.66-4.77.36-2.85.6-5.77a181.86,181.86,0,0,0,.69-19.69c0-2.1-.09-4.22-.18-6.39q-.23-5.59-.74-11.52a34.79,34.79,0,0,1,0-6.64,26.37,26.37,0,0,1,1.13-5.45,22,22,0,0,1,5.41-8.89A20.76,20.76,0,0,1,149,359.25Z"
            style="fill: rgb(79, 70, 229); transform-origin: 142.21px 405.625px;" id="el3liege2f1nz" class="animable">
          </path>
          <path d="M157.59,357h0l0,0Z" style="fill: rgb(79, 70, 229); transform-origin: 157.59px 357px;"
            id="elcyksgkpzw6i" class="animable"></path>
        </g>
      </g>
      <g id="elqxqkotc9iu">
        <g style="opacity: 0.3; transform-origin: 180.585px 370.43px;" class="animable">
          <path
            d="M231.43,365.61c-2.29,1.3-5.08,2.77-8.35,4.25q-2.49,1.14-5.36,2.28c-2.78,1.1-5.82,2.17-9.09,3.17q-2.65.81-5.51,1.53a123.83,123.83,0,0,1-12.9,2.54c-1.76.25-3.58.46-5.42.64-3.92.39-8,.6-12.28.6-.78,0-1.55,0-2.32,0h-.42c-1.84,0-3.64-.13-5.38-.27a83.45,83.45,0,0,1-8.72-1.19q-2.76-.54-5.31-1.22a68,68,0,0,1-7.92-2.64q-2.74-1.09-5.21-2.34a66,66,0,0,1-7.5-4.42,19.75,19.75,0,0,1,2.71-4.62,61,61,0,0,0,5.92,3.59c1.54.82,3.19,1.61,4.93,2.34a62.94,62.94,0,0,0,7.48,2.63c1.68.49,3.44.91,5.28,1.28a72.19,72.19,0,0,0,8.4,1.21c1.73.16,3.51.25,5.35.29l.48,0c.73,0,1.45,0,2.17,0a119.06,119.06,0,0,0,12.81-.69q2.79-.3,5.44-.72a116,116,0,0,0,12.94-2.75c1.93-.52,3.78-1.08,5.56-1.66,3.1-1,6-2.1,8.57-3.19,2-.81,3.74-1.63,5.39-2.43h0c2.65-1.28,4.91-2.5,6.75-3.58C230.38,361.76,230.89,363.56,231.43,365.61Z"
            style="fill: rgb(79, 70, 229); transform-origin: 180.585px 370.43px;" id="elnqk4zqhvyl" class="animable">
          </path>
        </g>
      </g>
      <g id="elhzif61qfsvf">
        <g style="opacity: 0.3; transform-origin: 181.304px 393.3px;" class="animable">
          <path
            d="M235.57,385.62a117.3,117.3,0,0,1-12.27,8c-1.67,1-3.42,1.9-5.27,2.82a106.1,106.1,0,0,1-11,4.81c-1.76.66-3.56,1.28-5.42,1.84a92.75,92.75,0,0,1-13,3c-1.76.27-3.56.5-5.38.66q-3.7.34-7.56.35c-1.36,0-2.74,0-4.12-.11-1.79-.09-3.58-.24-5.4-.46-.35,0-.7-.08-1.05-.12a77.82,77.82,0,0,1-9.28-1.8q-2.76-.72-5.32-1.6a73.15,73.15,0,0,1-7-2.8c-1.88-.87-3.67-1.8-5.38-2.77a74.46,74.46,0,0,1-11-7.66c-.1-2.55-.12-5,0-7.32a70,70,0,0,0,10.87,8.59q2.59,1.67,5.46,3.14a66.12,66.12,0,0,0,7.08,3.13c1.72.65,3.52,1.23,5.37,1.75a68.76,68.76,0,0,0,9.9,2h0c1.82.24,3.62.41,5.42.51a77.41,77.41,0,0,0,12.23-.31c1.83-.19,3.64-.44,5.4-.75a88.13,88.13,0,0,0,13-3.28c1.86-.62,3.68-1.29,5.44-2a106,106,0,0,0,10.62-4.94q2.81-1.5,5.3-3a116,116,0,0,0,11.38-7.8C234.91,381.5,235.25,383.52,235.57,385.62Z"
            style="fill: rgb(79, 70, 229); transform-origin: 181.304px 393.3px;" id="ele3th8eh0qbq" class="animable">
          </path>
        </g>
      </g>
      <g id="elex9ntul1mru">
        <g style="opacity: 0.3; transform-origin: 183.66px 421.04px;" class="animable">
          <path
            d="M237.77,413.43v1.21c-3.84,2.09-8.35,4.38-13.33,6.63-1.69.77-3.44,1.53-5.23,2.27-3.93,1.64-8.1,3.21-12.4,4.6-1.74.56-3.52,1.09-5.31,1.58a107.85,107.85,0,0,1-13.39,2.88c-1.78.26-3.56.46-5.35.61-2.12.18-4.25.28-6.36.28-1.38,0-2.75,0-4.12-.12-1.63-.1-3.25-.27-4.87-.5l-.49-.08A90.45,90.45,0,0,1,154.33,430c-1.79-.54-3.54-1.12-5.27-1.74q-3.67-1.34-7.15-2.88-2.61-1.15-5.08-2.4-3.17-1.57-6.08-3.26c-.41-2.27-.81-4.63-1.2-7q3.72,2.31,7.88,4.52c1.66.87,3.38,1.72,5.14,2.54q3.33,1.55,6.87,2.9c1.71.65,3.46,1.27,5.25,1.82a83.84,83.84,0,0,0,12.13,2.92c.45.08.9.15,1.36.21,1.32.19,2.66.33,4,.43a67.91,67.91,0,0,0,10.56-.17c1.79-.17,3.57-.39,5.36-.68a105.7,105.7,0,0,0,13.28-3q2.7-.78,5.34-1.68c4.23-1.42,8.31-3,12.17-4.67,1.8-.76,3.55-1.55,5.24-2.33,5.18-2.41,9.8-4.84,13.56-6.94C237.75,410.13,237.77,411.77,237.77,413.43Z"
            style="fill: rgb(79, 70, 229); transform-origin: 183.66px 421.04px;" id="eluro42tsh5l" class="animable">
          </path>
        </g>
      </g>
      <g id="el86ad9zrpimx">
        <g style="opacity: 0.3; transform-origin: 182.675px 445.405px;" class="animable">
          <path
            d="M236.63,432.39c-.12,1-.26,2-.41,3s-.35,2.26-.54,3.4c-2.7,1.28-6,2.8-9.77,4.43-1.65.72-3.4,1.45-5.21,2.19-4,1.63-8.34,3.3-12.89,4.88-1.73.6-3.48,1.19-5.25,1.75-4.52,1.44-9.16,2.74-13.79,3.79-1.77.42-3.54.78-5.3,1.1a99.27,99.27,0,0,1-9.85,1.34l-.19,0c-1.32.11-2.64.15-3.92.15-.49,0-1,0-1.46,0A49.7,49.7,0,0,1,154,456a51.88,51.88,0,0,1-5.48-2.12,61.21,61.21,0,0,1-9.37-5.27c-1.81-1.22-3.49-2.5-5-3.76a72.79,72.79,0,0,1-5.43-4.93l2.18-5.53c1.11,1.18,2.43,2.5,3.95,3.89s3.1,2.69,4.9,4.06a58.87,58.87,0,0,0,8.65,5.43,47.06,47.06,0,0,0,5.39,2.4,44.92,44.92,0,0,0,13.87,2.92A43.85,43.85,0,0,0,173,453l.23,0a91.69,91.69,0,0,0,10-1.41q2.64-.51,5.31-1.14c4.58-1.08,9.19-2.42,13.67-3.88,1.78-.58,3.54-1.18,5.26-1.79,4.54-1.62,8.88-3.32,12.83-5,1.83-.75,3.56-1.5,5.2-2.22C230.13,435.53,234,433.7,236.63,432.39Z"
            style="fill: rgb(79, 70, 229); transform-origin: 182.675px 445.405px;" id="elvrbmmmrhihl" class="animable">
          </path>
        </g>
      </g>
      <g id="elekkgiuk86wv">
        <path
          d="M203.24,360.58c-7.37,4.2-25.09,13.3-38.27,12.29-10.58-.81-15-7.83-16-13.62a16,16,0,0,1,2.23.72s18.39-13,51.86.66Z"
          style="fill-opacity: 0.7; opacity: 0.2; transform-origin: 176.105px 363.665px;" class="animable"></path>
      </g>
      <g id="elz1mv9mvux7g">
        <path
          d="M179.46,459.6c-1,0-2,0-3.07,0-39.56-.1-49.37-15.56-49.37-15.56l5.67-14.43a288.16,288.16,0,0,1-5.22-34.06c3.09,6.39,22.32,43.44,50.79,43.28C198.44,438.75,188.78,451,179.46,459.6Z"
          style="fill-opacity: 0.7; opacity: 0.2; transform-origin: 158.58px 427.575px;" class="animable"></path>
      </g>
      <g id="el0idxll0580b">
        <path
          d="M237.77,413.43a150.56,150.56,0,0,1-1.55,22c-10.45-12.59-7.16-35.42-7.16-35.42C230.44,408.9,235.06,412.22,237.77,413.43Z"
          style="fill-opacity: 0.7; opacity: 0.2; transform-origin: 233.17px 417.72px;" class="animable"></path>
      </g>
    </g>
    <path
      d="M151.19,360s18.39-13,51.86.66l25.11-5.86s16.24,44.11,6.5,89.32c0,0-21.79,15.63-58.27,15.55-39.57-.1-49.37-15.55-49.37-15.55l5.66-14.43c-3.41-16.48-7.82-42.9-4.46-56.58C133.33,352.31,151.19,360,151.19,360Z"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 182.396px 407.042px;"
      id="el96jr4ah1ld7" class="animable"></path>
    <path d="M163.66,367.09c11.56.45,25.6.37,35.68-6.06"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 181.5px 364.14px;"
      id="eln7h20lwsk2" class="animable"></path>
    <path d="M157.19,366.66c1,.11,2.07.19,3.11.26"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 158.745px 366.79px;"
      id="eldfwmhi5tbar" class="animable"></path>
    <path d="M166.11,438.57a25.7,25.7,0,0,0,.79,5.52"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 166.505px 441.33px;"
      id="elgy4ny17ysji" class="animable"></path>
    <path d="M163.07,369.72a25.38,25.38,0,0,0-1.84,14.38c4.46,17,7.14,33,4.84,50.24"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 163.904px 402.03px;"
      id="elaqrsu8jzmpc" class="animable"></path>
    <path d="M169.37,427a38.4,38.4,0,0,0,17.71.17"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 178.225px 427.561px;"
      id="el7wjy5fjn2qj" class="animable"></path>
    <path d="M133.08,391.07c6.28,14.82,17.62,27.46,32.13,34.58"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 149.145px 408.36px;"
      id="elpmhw6wvtumo" class="animable"></path>
    <path d="M130.12,382.67c.34,1.2.71,2.4,1.12,3.59"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 130.68px 384.465px;"
      id="elnyilskmq43e" class="animable"></path>
    <path
      d="M228.16,354.77a150,150,0,0,0,.61,43c3.74,24,9.6,23.77,9.6,23.77s33.45-41.34,42.39-58.05c0,0-2.12-24-11.53-29.1Z"
      style="fill: rgb(117, 117, 117); transform-origin: 253.83px 377.965px;" id="el7lx4128ohky" class="animable">
    </path>
    <g style="clip-path: url(&quot;#freepik--clip-path-3--inject-12&quot;); transform-origin: 253.865px 378.895px;"
      id="elicx53z69ug" class="animable">
      <g id="elr2hixcmg7ca">
        <g style="opacity: 0.3; transform-origin: 266.55px 358.415px;" class="animable">
          <path
            d="M274.76,373.18c-1.35,2-2.8,4.13-4.34,6.31-.79-4.75-1.71-9.14-2.71-13.2q-.72-3-1.52-5.71c-1.13-4-2.34-7.58-3.56-10.83q-1-2.75-2.08-5.16-1.12-2.61-2.21-4.83l4.86-2.42c.62,1.25,1.24,2.57,1.87,4,.71,1.61,1.42,3.32,2.13,5.16q1.8,4.62,3.52,10.23c.54,1.81,1.08,3.7,1.58,5.68Q273.64,367.43,274.76,373.18Z"
            style="fill: rgb(79, 70, 229); transform-origin: 266.55px 358.415px;" id="elftanzuuibbr" class="animable">
          </path>
        </g>
      </g>
      <g id="el2zm313v8bw9">
        <g style="opacity: 0.3; transform-origin: 254.17px 370.11px;" class="animable">
          <path
            d="M262.5,390.4c-1.58,2.11-3.15,4.21-4.72,6.26a192.42,192.42,0,0,0-3-20h0q-.59-3-1.26-5.79c-1.08-4.69-2.28-9-3.49-12.94q-.87-2.76-1.74-5.27c-.83-2.43-1.66-4.66-2.45-6.69l4.86-2.41c.74,1.87,1.52,3.91,2.3,6.13q.89,2.49,1.78,5.27c1.17,3.71,2.34,7.78,3.42,12.19q.69,2.81,1.34,5.79C260.71,378.33,261.72,384.15,262.5,390.4Z"
            style="fill: rgb(79, 70, 229); transform-origin: 254.17px 370.11px;" id="el8lh6n29k78r" class="animable">
          </path>
        </g>
      </g>
      <g id="elyg4tb72vbaq">
        <g style="opacity: 0.3; transform-origin: 241.385px 381.375px;" class="animable">
          <path
            d="M249.83,407c-1.64,2.12-3.18,4.07-4.57,5.83-1.22-8.91-2.76-17.71-4.4-26q-.57-2.91-1.16-5.72c-1.15-5.51-2.33-10.71-3.46-15.46-.44-1.87-.88-3.66-1.3-5.37-.71-2.88-1.39-5.53-2-7.92l4.9-2.44c.61,2.32,1.28,4.89,2,7.7.44,1.72.88,3.52,1.34,5.4h0c1.07,4.5,2.2,9.43,3.32,14.67.4,1.87.8,3.79,1.2,5.74h0C247.16,391,248.61,398.9,249.83,407Z"
            style="fill: rgb(79, 70, 229); transform-origin: 241.385px 381.375px;" id="el7bnpsncqkma" class="animable">
          </path>
        </g>
      </g>
      <g id="ellpklilxflte">
        <g style="opacity: 0.3; transform-origin: 250.885px 353.32px;" class="animable">
          <path
            d="M274.8,340.68c-2.3,1.83-4.83,3.79-7.6,5.81q-2.19,1.61-4.57,3.26Q259,352.31,254.8,355c-1.53,1-3.12,2-4.75,3q-4.25,2.58-8.92,5.14h0c-1.6.88-3.22,1.75-4.89,2.62-3,1.55-6.07,3.09-9.27,4.59,0-2.22.13-4.24.25-6.05h0q4-1.92,7.72-3.9c1.65-.89,3.27-1.77,4.85-2.66,3-1.66,5.81-3.34,8.52-5q2.43-1.49,4.71-3c2.66-1.72,5.17-3.43,7.53-5.1,1.57-1.11,3.08-2.19,4.52-3.26,2.39-1.77,4.59-3.48,6.61-5.09a15.81,15.81,0,0,1,1.78,2.2A19.79,19.79,0,0,1,274.8,340.68Z"
            style="fill: rgb(79, 70, 229); transform-origin: 250.885px 353.32px;" id="elt91ld3341qj" class="animable">
          </path>
        </g>
      </g>
      <g id="eljhoomhioyms">
        <g style="opacity: 0.3; transform-origin: 253.675px 372.485px;" class="animable">
          <path
            d="M279.67,356c-2.32,2.06-4.78,4.21-7.37,6.42q-2.24,1.92-4.59,3.89c-2.63,2.19-5.35,4.42-8.15,6.65-1.56,1.24-3.15,2.49-4.76,3.72h0c-3,2.3-6.05,4.59-9.15,6.82h0c-1.59,1.15-3.18,2.28-4.79,3.39-4.13,2.85-8.32,5.58-12.49,8.09-.28-2-.5-3.94-.69-5.84,4-2.49,8-5.18,12-8,1.59-1.12,3.18-2.25,4.75-3.41,3.09-2.25,6.13-4.56,9.09-6.88,1.58-1.24,3.15-2.48,4.68-3.72,2.75-2.2,5.41-4.41,8-6.57,1.54-1.31,3.06-2.59,4.53-3.86,2.68-2.33,5.22-4.58,7.59-6.71C278.87,352.09,279.32,354.14,279.67,356Z"
            style="fill: rgb(79, 70, 229); transform-origin: 253.675px 372.485px;" id="el93juet668st" class="animable">
          </path>
        </g>
      </g>
      <g id="elixh9sqk9j9">
        <path
          d="M280.76,363.45c-8.95,16.71-42.4,58.05-42.4,58.05s-5,.21-8.69-18.62c2.73,10.15,10.13,11.19,10.13,11.19L273.88,369c-3.63-7.72-.51-29.94-.42-30.53C279.31,347,280.76,363.45,280.76,363.45Z"
          style="fill-opacity: 0.7; opacity: 0.2; transform-origin: 255.215px 379.985px;" class="animable"></path>
      </g>
    </g>
    <path
      d="M228.16,354.77a150,150,0,0,0,.61,43c3.74,24,9.6,23.77,9.6,23.77s33.45-41.34,42.39-58.05c0,0-2.12-24-11.53-29.1Z"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 253.83px 377.965px;"
      id="elh5gzxcpg7f7" class="animable"></path>
    <path
      d="M203.05,360.63s-13.85-10.16-32.21-12.3S153,357,153,357a3.17,3.17,0,0,0-1.76,4C152,364.07,176.86,367,203.05,360.63Z"
      style="fill: rgb(117, 117, 117); transform-origin: 177.051px 356.234px;" id="elonkrnq3flh" class="animable">
    </path>
    <g style="clip-path: url(&quot;#freepik--clip-path-4--inject-12&quot;); transform-origin: 177.49px 356.149px;"
      id="elrqwscnzap1e" class="animable">
      <g id="elcy3ey2gs12f">
        <g style="opacity: 0.3; transform-origin: 165.26px 356.149px;" class="animable">
          <path
            d="M169.74,348.21a34.85,34.85,0,0,0-1.29,3.7c-.45,1.5-.9,3.28-1.31,5.36s-.75,4.38-1,7c-2-.09-3.74-.24-5.36-.41.25-2.49.58-4.72.93-6.72s.77-3.82,1.2-5.4c.36-1.37.74-2.57,1.11-3.64A34,34,0,0,1,169.74,348.21Z"
            style="fill: rgb(79, 70, 229); transform-origin: 165.26px 356.149px;" id="elsk7bf218ys" class="animable">
          </path>
        </g>
      </g>
      <g id="elct7f489qz3g">
        <g style="opacity: 0.3; transform-origin: 181.19px 357.065px;" class="animable">
          <path
            d="M184.43,351.43c0,.68,0,1.41,0,2.2-.07,1.58-.18,3.38-.39,5.4-.15,1.5-.35,3.12-.6,4.85-1.89.17-3.72.29-5.49.38.35-2.19.6-4.2.78-6,.2-2.05.31-3.85.36-5.39,0-1.15,0-2.15,0-3C180.93,350.31,182.73,350.85,184.43,351.43Z"
            style="fill: rgb(79, 70, 229); transform-origin: 181.19px 357.065px;" id="elk4wgu0wbfvq" class="animable">
          </path>
        </g>
      </g>
      <g id="elqtxp7wu82zr">
        <g style="opacity: 0.3; transform-origin: 177.49px 356.815px;" class="animable">
          <path
            d="M203.05,360.63c-2,.47-3.88.9-5.81,1.28-4.65-1.25-9.09-2.19-13.24-2.88-1.83-.31-3.59-.57-5.31-.79-4.23-.55-8.1-.83-11.55-1-2-.08-3.79-.1-5.46-.09a94.91,94.91,0,0,0-9.75.52,3.35,3.35,0,0,1,1-.69,7.88,7.88,0,0,1,1.91-5c2.17-.16,4.87-.27,8-.26,1.72,0,3.58,0,5.57.13,3.21.16,6.76.45,10.6.94q2.58.31,5.34.78c3.27.54,6.73,1.22,10.32,2.09h0A63.88,63.88,0,0,1,203.05,360.63Z"
            style="fill: rgb(79, 70, 229); transform-origin: 177.49px 356.815px;" id="elko25xvs604j" class="animable">
          </path>
        </g>
      </g>
    </g>
    <path
      d="M203.05,360.63s-13.85-10.16-32.21-12.3S153,357,153,357a3.17,3.17,0,0,0-1.76,4C152,364.07,176.86,367,203.05,360.63Z"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 177.051px 356.234px;"
      id="eloxnzo10gdg" class="animable"></path>
    <path d="M173,355.84a35.2,35.2,0,0,1,5.25,1.17"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 175.625px 356.425px;"
      id="el8m93q9ihxor" class="animable"></path>
    <path d="M153,357a54.64,54.64,0,0,1,16.41-1.54"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 161.205px 356.184px;"
      id="elowm9kon6rc" class="animable"></path>
    <path d="M121.2,355.76c0,1.76.15,3.52.34,5.27"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 121.37px 358.395px;"
      id="eld78wrplarep" class="animable"></path>
    <path d="M129.24,334.35c-5.56,3.64-7.53,11.53-8,17.69"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 125.24px 343.195px;"
      id="elww2ge1laa89" class="animable"></path>
    <path d="M261.18,380.85a15.75,15.75,0,0,1-5,3.61"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 258.68px 382.655px;"
      id="eluxvv2u1cqtk" class="animable"></path>
    <path d="M277,345.83c-3.35,10.6-6.92,22.21-13.31,32"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 270.345px 361.83px;"
      id="el21f3ys2jdsn" class="animable"></path>
    <path d="M238.37,421.5s-5.16,2.51-12-11.64"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 232.37px 415.766px;"
      id="el4mqx4np1orl" class="animable"></path>
    <path d="M229.8,435.06c-5,8.69-12.08,16.77-21.6,20.45"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 219px 445.285px;"
      id="eld46vwlf1hn" class="animable"></path>
    <path d="M233.65,427c-.52,1.41-1.13,2.78-1.78,4.13"
      style="fill: none; stroke: rgb(38, 50, 56); stroke-linecap: round; stroke-linejoin: round; transform-origin: 232.76px 429.065px;"
      id="eltxisdk83kt" class="animable"></path>
  </g>
  <defs>
    <filter id="active" height="200%">
      <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
      <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
    <filter id="hover" height="200%">
      <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>
      <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>
      <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>
      <feMerge>
        <feMergeNode in="OUTLINE"></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
      <feColorMatrix type="matrix"
        values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 ">
      </feColorMatrix>
    </filter>
  </defs>
  <defs>
    <clipPath id="freepik--clip-path--inject-12">
      <path
        d="M151.19,362.49S130.74,338.66,127,330.42c0,0-14.08-.46-16,11.89a95,95,0,0,0,0,24.88s13.88,37.88,19.65,52.22Z"
        style="fill:#757575;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
    </clipPath>
    <clipPath id="freepik--clip-path-2--inject-12">
      <path
        d="M151.19,360s18.39-13,51.86.66l25.11-5.86s16.24,44.11,6.5,89.32c0,0-21.79,15.63-58.27,15.55-39.57-.1-49.37-15.55-49.37-15.55l5.66-14.43c-3.41-16.48-7.82-42.9-4.46-56.58C133.33,352.31,151.19,360,151.19,360Z"
        style="fill:#757575;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
    </clipPath>
    <clipPath id="freepik--clip-path-3--inject-12">
      <path
        d="M228.16,354.77a150,150,0,0,0,.61,43c3.74,24,9.6,23.77,9.6,23.77s33.45-41.34,42.39-58.05c0,0-2.12-24-11.53-29.1Z"
        style="fill:#757575;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
    </clipPath>
    <clipPath id="freepik--clip-path-4--inject-12">
      <path
        d="M203.05,360.63s-13.85-10.16-32.21-12.3S153,357,153,357a3.17,3.17,0,0,0-1.76,4C152,364.07,176.86,367,203.05,360.63Z"
        style="fill:#757575;stroke:#263238;stroke-linecap:round;stroke-linejoin:round"></path>
    </clipPath>
  </defs>
</svg>
</template>

<script>
export default {
  props: [],
  data() {
    return {};
  },
};
</script>

<style scoped>
svg#freepik_stories-directions:not(.animated) .animable {
  opacity: 0;
}

svg#freepik_stories-directions.animated #freepik--background-simple--inject-12 {
  animation: 6s Infinite linear floating;
  animation-delay: 0s;
}

svg#freepik_stories-directions.animated #freepik--Map--inject-12 {
  animation: 6s Infinite linear floating;
  animation-delay: 0s;
}

svg#freepik_stories-directions.animated #freepik--Directions--inject-12 {
  animation: 6s Infinite linear floating;
  animation-delay: 0s;
}

@keyframes floating {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
