<template>
 <div class="relative overflow-hidden">
  <header class="relative">
   <div class="bg-gray-50 pt-6">
    <nav class="relative mx-auto flex max-w-7xl items-center justify-between px-6" aria-label="Global">
     <div class="flex flex-1 items-center">
      <div class="flex w-full items-center justify-between md:w-auto">
       <a href="https://cx-engine.com/">
        <img class="h-8 w-auto sm:h-10" src="../assets/cx-engine-logo-inline--dark.svg" alt="logo" />
       </a>
       <div class="-mr-2 flex items-center md:hidden">
        <button type="button" class="focus-ring-inset relative inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-white" aria-expanded="false">
         <span class="absolute -inset-0.5"></span>
         <span class="sr-only">Open main menu</span>
         <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
         </svg>
        </button>
       </div>
      </div>
      <div class="hidden space-x-8 md:ml-10 md:flex"><a href="#" class="text-base font-medium text-white hover:text-gray-800">Product</a><a href="#" class="text-base font-medium text-white hover:text-gray-800">Features</a></div>
     </div>
     <div class="hidden md:flex md:items-center md:space-x-6">
      <a href="#" class="inline-flex items-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white hover:bg-gray-700">Learn more</a>
     </div>
    </nav>
   </div>
  </header>

  <main class="">
   <div class="bg-gray-50 pt-10 sm:pt-16 lg:overflow-hidden lg:pb-14 lg:pt-8 min-h-screen">
    <div class="mx-auto max-w-7xl lg:px-8">
     <div class="lg:grid lg:grid-cols-2 lg:gap-8">
      <div class="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
       <div class="lg:py-24">
        <h1 class="text-4xl font-bold tracking-tight text-black sm:text-6xl lg:mt-6 xl:text-6xl">
         <span class="block">Get more from</span>
         <span class="block text-indigo-600">Expert Statistics</span>
        </h1>
        <p class="mt-3 text-base text-gray-800 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">Learn more about our <span class="font-bold"><router-link to="/expert-statistics/the-use-case-for-expert-statistics">Expert Statistics</router-link></span> solutions.</p>
        <p class="mt-10 text-base text-gray-800 sm:text-2xl lg:text-xl xl:text-2xl"><span class="font-bold">Thank you!</span> Our team will be in contact soon!</p>
        <p class="mt-3 text-base text-gray-800 sm:text-2xl lg:text-xl xl:text-2xl">You should find a confirmation on your email.</p>
        <p class="mt-10 text-base text-gray-800 sm:text-sm lg:text-xs xl:text-sm">Should you have any queries please contact <span class="font-bold">developers@cx-engine.com.</span></p>
        <div class="mt-10">
         <router-link to="/" class="w-auto rounded-md bg-indigo-600 px-4 py-3 font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-300 focus:ring-offset-2 focus:ring-offset-gray-900">Back to home page</router-link>
        </div>
       </div>
      </div>
      <div class="-mb-16 mt-12 sm:-mb-48 lg:relative lg:m-0">
       <div class="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
        <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
        <img class="w-full lg:absolute lg:inet-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-xl" src="../assets/cx-engine-logo-without-txt.svg" alt="" />
       </div>
      </div>
     </div>
    </div>
   </div>

   <!-- More main page content here... -->
  </main>
 </div>
</template>

<script>
export default {};
</script>

<style></style>
