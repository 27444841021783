<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 xl:gap-16 lg:py-16 lg:px-6 ">
      <div class="text-gray-500 sm:text-lg">
        <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 md:text-4xl dark:text-white">
          Trust in our top-notch technology
        </h2>
        
        <p class="mb-8 font-light lg:text-xl dark:text-gray-400">
          Together with our partners, we are already bringing value to hundreads of businesses with our battle-tested APIs.
        </p>
        
        <div class="grid gap-6 lg:grid-cols-1 dark:border-gray-700 sm:grid-cols-2">
          <div class="flex">
            <div class="mr-4 shrink-0">
              <PhoneArrowDownLeftIcon class="mt-1 w-7 h-7 text-primary-600 dark:text-primary-500" />
            </div>
            <div>
              <p class="mb-1 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">125 million</p>
              <p class="font-light text-gray-500 dark:text-gray-400">calls analyzed each month</p>
            </div>
          </div>
          <div class="flex">
            <div class="mr-4 shrink-0">
              <ShieldCheckIcon class="mt-1 w-7 h-7 text-primary-600 dark:text-primary-500" />
            </div>
            <div>
              <p class="mb-1 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">99.9%</p>
              <p class="font-light text-gray-500 dark:text-gray-400">of availability on our APIs</p>
            </div>
          </div>
          <div class="flex">
            <div class="mr-4 shrink-0">
              <HomeModernIcon class="mt-1 w-7 h-7 text-primary-600 dark:text-primary-500" />
            </div>
            <div>
              <p class="mb-1 text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white">135+</p>
              <p class="font-light text-gray-500 dark:text-gray-400">businesses relying on our APIs</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Illustration -->
      <Directions class="hidden mx-auto mb-4 sm:flex" />
    </div>
  </section>
</template>

<script>
import Directions from "@/components/illustrations/Directions";
import {
  PhoneArrowDownLeftIcon,
  ShieldCheckIcon,
  HomeModernIcon,
} from "@heroicons/vue/24/solid";

export default {
 components: {
  Directions,
  PhoneArrowDownLeftIcon,
  ShieldCheckIcon,
  HomeModernIcon,
 },
 data() {
  return {};
 },
};
</script>


<style></style>
