<template>
  <!-- <div class="bg-gray-800" v-show="this.$route.name === 'home'">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <h2 class="text-center text-gray-400 text-base font-semibold">Join our partners</h2>
      <div class="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-2">
      <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
        <img class="h-12" src="../../assets/encom.png" alt="encom" />
      </div>
      <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
        <img class="h-24" src="../../assets/bluerockTEL.png" alt="bluerockTEL" />
      </div>
      </div>
    </div>
    <a href="https://storyset.com/online">Online illustrations by Storyset</a>
  </div> -->

  <footer class="bg-gray-50 dark:bg-gray-800">
    <div class="p-4 py-6 mx-auto max-w-screen-xl md:p-8 lg:-10">
      <div class="grid grid-cols-2 gap-8 lg:grid-cols-6">
        <div class="col-span-2">
          <a href="#" class="flex items-center mb-2 text-2xl font-semibold text-gray-900 sm:mb-0 dark:text-white">
            <img class="h-8 w-auto sm:h-10" src="../../assets/cx-engine-logo-inline.svg" alt="" />
            <span class="sr-only">CX-Engine</span>
          </a>
          <p class="my-4 font-light text-gray-500 dark:text-gray-400">
            CX-Engine is a set of tools enhancing your 3CX experience and providing deep customer insights. CRM connectors, CDFs, Statistics and more.
          </p>
          <ul class="flex mt-5 space-x-3">
            <li>
              <a href="https://github.com/CX-engine" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" /></svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/cx-engine/about/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400">
                <svg class="w-5 h-5" viewBox="0 0 448 512" fill="currentColor"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" fill="currentColor"/></svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="lg:mx-auto">
          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2>
          <ul class="text-gray-500 dark:text-gray-400">
              <!-- <li class="mb-4">
                  <a href="#" class="hover:underline">Blog</a>
              </li> -->
              <li class="mb-4">
                  <router-link to="/contact-us" class="hover:underline">Contact Us</router-link>
              </li>
              <!-- <li class="mb-4">
                  <a href="#" class="hover:underline">Privacy Policy</a>
              </li> -->
          </ul>
        </div>
        <div class="lg:mx-auto">
          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Partners</h2>
          <ul class="text-gray-500 dark:text-gray-400">
              <li class="mb-4">
                  <a href="https://bluerocktel.com" target="_blank" class="hover:underline">BlueRockTEL</a>
              </li>
              <li class="mb-4">
                  <a href="https://encom-conseil.fr" target="_blank" class="hover:underline">Encom</a>
              </li>
          </ul>
        </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8">
      <span class="block text-sm text-center text-gray-500 dark:text-gray-400">© 2022-{{ currentYear }} <a href="#" class="hover:underline">CX-Engine</a>. All Rights Reserved.</span>
    </div>
  </footer>
</template>

<script>
export default {
  props: [],
  components: {},
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {},
};
</script>
